import React, { useState,useRef,useEffect } from 'react'
import style from './InnerHeadBar.module.css'
import EnglishWagonLogo from "../../assests/English wagon logo_Actual 1.png";
import NotificationICon from "../../assests/26. Notification.png"
import MessageICon from "../../assests/Inbox.png";
import { useNavigate,useLocation } from 'react-router-dom';
import ActiveCalendar from "../../assests/activeCalendar.png";
import ActiveWorkOut from "../../assests/ActiveWeightlifting-sport-exercise-gym (1).png";
import BillingIcon from '../../assests/Billing.png'
import Coin from "../../assests/indian-rupee 1.png"
import Profile from "../../assests/Profile-PNG-File.png"
import { GiHamburgerMenu } from "react-icons/gi";
import { useDispatch } from 'react-redux';
import DashboardIcon from '../../assests/Dashboard.png'
import LearningIcon from '../../assests/Learning.png'
import PerformanceIcon from '../../assests/Performance.png'
import ProfileIcon from '../../assests/User.png'
import { googleLogout } from "@react-oauth/google";
import { fetchUserDetails } from '../../Slices/UserSlice';
import { baseurl } from "../Url";
import { useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import silverMedal from "../../assests/SilverPoint.png"
import bronzeMedal from "../../assests/BronzeMedal.png"
import goldMedal from "../../assests/GoldMedal.png"


const InnerHeadBar = ({ finished, timeRunning, setFinishPopup }) => {
  const navigate=useNavigate();
  const location=useLocation()
  const popupRef = useRef(null);
  const { userDetails, status: userStatus, error: userError } = useSelector((state) => state.user);

  const UserName = localStorage.getItem("username");

  const [notificationPopUp,setNotificationPopUp]=useState(false);
  const [notificationList,setNotificationList]=useState([])
  const [pointPopup,setPointPopup]=useState(false)

  const getInitials = (name) => {
    if (!name) return '';

    const nameParts = name.trim().split(' ');
    
    if (nameParts.length === 1) {
      return nameParts[0].charAt(0).toUpperCase();
    } else {
      // If two or more names, return the first letter of the first two names
      return nameParts[0].charAt(0).toUpperCase() + nameParts[1].charAt(0).toUpperCase();
    }
  };

  const userInitials = getInitials(UserName);

  const handleProfileNavigation = () => {
    if (location.pathname==="/daily/drill"&&!finished && timeRunning) {
      setFinishPopup(true);
    }else  if(location.pathname==="/weekly/workout"&&!finished&&timeRunning){
      setFinishPopup(true)
    } else {
      navigate("/profile");
    }
  };

  const handleNotificationClick = (e) => {
    e.stopPropagation();
    if (location.pathname==="/daily/drill"&&!finished && timeRunning) {
      setFinishPopup(true);
    }else  if(location.pathname==="/weekly/workout"&&!finished&&timeRunning){
      setFinishPopup(true)
    } else {
      setNotificationPopUp(!notificationPopUp);
    }
  };

  

  const [isOpen,setIsOpen]=useState(false)

  const menu=[
    {
        id:1,
        name:"Homepage",
        router:"/",
        icon:DashboardIcon,
        specialPaths:["/daily/drill","/weekly/workout"]
    },
    {
      id:2,
      name:"Modules",
      router:"/new/learnings",
      icon:LearningIcon,
      specialPaths:["/new/learnings/detail","/assessments"]
  },
    {
        id:3,
        name:"My Dashboard",
        router:"/performance",
        icon:PerformanceIcon
    },
    {
        id:4,
        name:"My Profile",
        router:"/profile",
        icon:ProfileIcon
    },
    {
        id:5,
        name:"My Subscription",
        router:"/subscribe/details",
        icon:BillingIcon
    },
    // {
    //     id:6,
    //     name:"Settings",
    //     router:"",
    //     icon:SettingsIcon
    // },
]

const UserID = localStorage.getItem("USERID");
const key = JSON.parse(localStorage.getItem("access-token"));

 
 const dispatch = useDispatch();

const [selectedMenu,setSelectedMenu]=useState({})
const [logout, setLogout] = useState(false);
const [sessionExpire,setSessionExpire]=useState(false);

const handleClick=(item)=>{
  if(location.pathname==="/daily/drill"&&!finished&&timeRunning){
    setFinishPopup(true)
  }
  else  if(location.pathname==="/weekly/workout"&&!finished&&timeRunning){
    setFinishPopup(true)
  }
  else{
    setSelectedMenu(item)
    navigate(item.router)
    setIsOpen(false);
  }
}



const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("access-token");
    localStorage.removeItem("ClubID");
    localStorage.removeItem("ORGID");
    localStorage.removeItem("USERID");
    localStorage.removeItem("mentor");
    localStorage.removeItem("learnertoken");
    localStorage.removeItem("learneruserid");
    localStorage.removeItem("username");
    localStorage.removeItem("newLearningId");
    localStorage.removeItem("MainLevelId");
    localStorage.removeItem("SubLevelId");
    localStorage.removeItem("clickedItem");
    localStorage.removeItem("clickeditemDes");
    localStorage.removeItem("functionCalled");
    localStorage.removeItem("rzp_checkout_anon_id");
    localStorage.removeItem("rzp_device_id");
    localStorage.removeItem("countrydetails");
    localStorage.removeItem("timezone");
    localStorage.removeItem("currency");
    localStorage.removeItem("adminmapped");
    localStorage.removeItem('dailySnoozeTriggered');
    localStorage.removeItem('weeklySnoozeTriggered');
    localStorage.removeItem('hasShownDailyDrill');
    localStorage.removeItem("trialUser")
    localStorage.removeItem("categoryID")
    googleLogout();
    navigate("/login");
    window.location.reload();
  };

  async function getNotification() {
    await fetch(`${baseurl}/notifications/user`, {
      method: "POST",
      body: JSON.stringify(
        {user_id:UserID,
          org_code:""
        }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
        setNotificationList(data.data)
        }
        else{
          setNotificationList([])
        }
      });
  }

  async function notificationStatus(id) {
    await fetch(`${baseurl}/notification/status_change`, {
      method: "POST",
      body: JSON.stringify(
        {id:id,
        }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
       
        }
       
      });
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target) ) {
        setNotificationPopUp(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  

  useEffect(() => {
    const currentMenuItem = menu.find(item => 
        item.router === location.pathname || 
        (item.specialPaths && item.specialPaths.includes(location.pathname))
    );
    if (currentMenuItem) {
        setSelectedMenu(currentMenuItem);
    }
  }, [location.pathname]);


  useEffect(() => {
  
  if (UserID) {
    const token = key; // Assuming `key` is the token
    dispatch(fetchUserDetails({ userid: UserID, token })); 
    getNotification();
  } else {
    localStorage.removeItem("access-token");
    localStorage.removeItem("ClubID");
    localStorage.removeItem("ORGID");
    localStorage.removeItem("USERID");
    navigate("/login");
  }
  }, [UserID, dispatch, navigate,key]);

  // useEffect(() => {
  //   const scriptId = "tawk-chatbot-script";

  //   if (location.pathname === "/support") {
  //     if (!document.getElementById(scriptId)) {
  //       const script = document.createElement("script");
  //       script.id = scriptId;
  //       script.src = "https://embed.tawk.to/674e9d454304e3196aeba37f/1ie5hcm7s"; // Replace with your property details
  //       script.async = true;
  //       script.charset = "UTF-8";
  //       script.setAttribute("crossorigin", "*");
  //       document.body.appendChild(script);

  //       script.onload = () => {
  //         if (window.Tawk_API && typeof window.Tawk_API.showWidget === "function") {
  //           window.Tawk_API.showWidget();
  //         }
  //       };
  //     } else {
  //       if (window.Tawk_API && typeof window.Tawk_API.showWidget === "function") {
  //         window.Tawk_API.showWidget();
  //       }
  //     }
  //   } else {
  //     if (window.Tawk_API && typeof window.Tawk_API.hideWidget === "function") {
  //       window.Tawk_API.hideWidget();
  //     }
  //     const script = document.getElementById(scriptId);
  //     if (script) {
  //       document.body.removeChild(script);
  //     }
  //   }
  // }, [location.pathname]);

  

  return (
    <div className={style.Container}>
    <div className={style.topBar}>
      <div className={style.topImg}>
        <img src={EnglishWagonLogo} alt="English Wagon Logo" />
      </div>

      <div className={style.topBarMenu}>
        <div className={style.userPoint}><img src={Coin} alt=''/>
        <span onClick={()=>setPointPopup(false)}>{userDetails&&userDetails.Rewards?.Rewards} xp</span></div>
      {/* <div className={style.notificationIconImage}>
          <img src={MessageICon} alt='Inbox'/>
        </div> */}
        <div style={{position:"relative"}} className={style.iconImage}>
          <img onClick={handleNotificationClick} src={NotificationICon} alt='notification'/>
        </div>
        <div className={style.userIcon}>
            <img src={userDetails&&userDetails.profile_image?userDetails&&userDetails.profile_image:Profile} alt='user' onClick={handleProfileNavigation}/>
        </div>

       <div className={style.menuIcon}>
         <GiHamburgerMenu  onClick={()=>setIsOpen(prevState => !prevState)}/>  
       </div>
      </div>
    </div>
    {notificationPopUp&&(
      <div ref={popupRef} className={style.notificationPopUp}>
        <div className={style.notificationHeader}>
          <p>Notifications</p>
       </div>
       <div className={style.notificationContent}>
        {notificationList&&notificationList.length>0?notificationList.map((notification,i)=>(
          <div key={i} className={style.notificationItemContainer} style={{background:notification.status==="active"?"White":"#d9d9d98c"}}>
          <div className={style.notificationItem} onClick={()=>{
            if(notification.status==="active"){
              notificationStatus(notification._id)
              if(notification.type==="Subscription"){
                navigate("/subscribe/details")
              }
            }
          }}>
          <div className={style.notificationItemLeft}>
           <div className={style.notificationLeftImage}><img src={notification.image} alt=""/></div>
          </div>
          <div className={style.notificationItemRight}>
           <div className={style.notificationRightHeader}>
             <p>{notification.type}</p>
           </div>
           <div className={style.notificationRightContent}>
             <p>{notification.title}</p>
             <span>{notification.description}</span>
           </div>
           <div className={style.notificationRightBottom}>
             <p>{notification.time}</p>
           </div>
          </div>
          </div>
       </div>

        )):"No Notification"}
      

      
       
       </div>
      </div>
    )}
    {isOpen?(
      <>
      <div className={style.sidebar}>
        <div className={style.MenuContainer}>
            {menu.map((item)=>{
                return(
                    <div onClick={()=>{
                        handleClick(item)
                    }} key={item.id} className={selectedMenu.id===item.id?style.selectedMenu:style.menuItem}>
                <div className={style.menuItemImage}><img src={item.icon} alt={item.name}/></div>
                <div className={style.menuItemText}><p>{item.name}</p></div>
            </div>
                )
            })}
            
        </div>
        <div className={style.bottomContent}>
            <div className={style.separateLine}></div>
            <div className={selectedMenu.id===7?style.selectedBottomText:style.bottomText}>
                <p  onClick={()=>{
                  handleClick({
                    id:7,
                    name:"Support",
                    router:"/support",
                   
                })
                }} className={style.supportText}>FAQs and Help</p>
            </div>
            <div className={style.bottomText}>
                <p className={style.logoutText} onClick={()=>{
                  if(location.pathname==="/daily/drill"&&!finished&&timeRunning){
                    setFinishPopup(true)
                  }
                  else  if(location.pathname==="/weekly/workout"&&!finished&&timeRunning){
                    setFinishPopup(true)
                  }else{
                    setLogout(true)
                  }
                }}>Log out</p>
            </div>
        </div>
        {logout ? (
        <>
        <div className={style.popupOverlay}></div>
          <div className={style.Popup}>
            <div className={style.popupText}>
              <p>Are you sure you want to log out?</p>
            </div>
            <div className={style.PopupButton}>
            <button
              className={style.noButton}
                onClick={() => {
                  setLogout(false);
                }}
              >
                NO
              </button>
              <button
              className={style.yesButton}
                onClick={(e) => {
                  e.preventDefault();
                  handleLogout(e);
                }}
              >
                YES
              </button>
             
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      {sessionExpire ? (
        <>
        <div className={style.popupOverlay}></div>
          <div className={style.Popup}>
            <div className={style.popupText}>
              <p>Your session expired please login again</p>
            </div>
            <div className={style.PopupButton}>
           
              <button
              className={style.yesButton}
                onClick={(e) => {
                  e.preventDefault();
                  handleLogout(e);
                }}
              >
                Login
              </button>
             
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
      </>
    ):
    
    ("")

    }

    {pointPopup&&(
      <div className={style.pointPopup}>
        <div className={style.pointPopupClose}>
          <IoClose  onClick={()=>setPointPopup(false)}/>
        </div>
        <div className={style.pointPopupImageContainer}>
          {userDetails&&userDetails.Rewards?.Badge?
          <div className={style.pointPopupImage}>
            
            <img src={userDetails.Rewards?.Badge==="Silver"?
                  silverMedal
                  :userDetails.Rewards?.Badge==="Bronze"?bronzeMedal
                  :userDetails.Rewards?.Badge==="Gold"?goldMedal
                  :""} alt="avatar" />
          </div>
          :''}
          </div>
        <div className={style.pointPopupContent}>
        <div className={style.pointPopupTopContent}>
        <img src={Coin} alt=''/>
        <p>{userDetails&&userDetails.Rewards?.Rewards} xp</p>
        </div>
        <div className={style.pointPopupBottomContent}>
          <span>You need</span>
          <p>2450 xp</p>
          <span>more to move to Gold</span>
        </div>
        </div>
        <div className={style.footerText}>
          <p>How do I reach Bronze, Silver or Gold performer?</p>
        </div>
       
      </div>
    )}
  </div>
  )
}

export default InnerHeadBar