import React, { useEffect, useState, useRef } from "react";
import style from "./WeeklyWorkout.module.css";
import { useLocation } from "react-router-dom";
import { baseurl } from "../Url";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import WorkOut from "../../assests/ActiveWeightlifting-sport-exercise-gym (1).png";
import TimerImage from "../../assests/Chronometer.png";
import { Doughnut } from "react-chartjs-2";
import TrophyImage from "../../assests/Trophy.png";
import { FaCheck, FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import SecondTrophyImage from "../../assests/SecondTrophy.png";
import ThirdTrophyImage from "../../assests/ThirdTrophy.png";
import FourthTrophyImage from "../../assests/FourthTrophy.png";
import ClockImage from "../../assests/alarm-clock.png";
import Loader from "../Loder/Loader";
import {
  Alert,
  Snackbar,

} from "@mui/material";
import { useOutletContext } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import OutlinedInput from '@mui/material/OutlinedInput';
import Coins from "../../assests/Animations/CoinsGif.gif"
import Lottie from "lottie-react";
import coinsLottie from "../../Animation/Animation -Coins.json"
import { fetchUserDetails } from '../../Slices/UserSlice';
import { useDispatch,useSelector } from "react-redux";

const WeeklyWorkout = () => {
  const { finishPopup, setFinishPopup,setTimeRunning,finished,setFinished,timeRunning,setAttended,attended } = useOutletContext();
  const location = useLocation();
  const dispatch = useDispatch();
  const state = location.state;

  const { userDetails, status: userStatus, error: userError } = useSelector((state) => state.user);

  const USERID = localStorage.getItem("USERID");
  const OrgID = localStorage.getItem("ORGID");

  const [assessment, setAssessment] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentAssessment, setCurrentAssessment] = useState({});
  const [validateClicked, setValidateClicked] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [result, setResult] = useState(false);
  const [assessmentData, setAssessmentData] = useState({});
  const [timeLeft, setTimeLeft] = useState(null);
  const [summaryDetail,setSummaryDetail]=useState({})
  const [expandedIndex, setExpandedIndex] = useState(null); 
  const [timeOuted,setTimeOuted]=useState(false);
  const [randomID, setRandomID] = useState("");
  const [allotedTime, setAllotedTime] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error,setError]=useState("")
  const inputRefs = useRef({});
  const highlightRefs = useRef({});
  const quillRef = useRef();
  const [achievementPopup,setAchievementPopup]= useState(false);
  const [collectPopup,setCollectPopup]= useState(false);
  const [carouselData,setCarouselData]=useState([])
  const [points,setPoints]=useState("")

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  const timerRef = useRef();

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  const handleSubmit = () => {
    if (allotedTime !== null) {
      const totalTimeInSeconds = allotedTime * 60; 
      const timeTaken = totalTimeInSeconds - timeLeft; 
  
      assessmentValidate(timeTaken); 
    }
  };
  

  const attendedCount = Object.values(assessmentData).filter(
    (data) => data.status === "attended"||data.status === "skipped"
  ).length;

  const totalCount = assessment.length;
  const progressPercentage = totalCount > 0 ? (attendedCount / totalCount) * 100 : 0;
  

  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const currentDate = getCurrentDate();

  const numbers = Array.from(
    { length: assessment.length },
    (_, index) => index + 1
  );

  const handleOptionClick = (option, index) => {
    const uniqueId = currentAssessment.unique_id;
    const isMultiSelect = currentAssessment.isMultiSelect;

    setAssessmentData((prev) => {
        const prevAnswers = prev[uniqueId]?.answers || [];
        let newAnswers;

        if (isMultiSelect) {
            if (prevAnswers.includes(option)) {
                newAnswers = prevAnswers.filter((ans) => ans !== option);
            } else {
                newAnswers = [...prevAnswers, option];
            }
        } else {
            newAnswers = [option];
        }

        return {
            ...prev,
            [uniqueId]: {
                ...prev[uniqueId],
                answers: newAnswers,
            },
        };
    });

    setSelectedOptions((prev) => {
        if (isMultiSelect) {
            const selectedIndexes = prev[uniqueId] || [];
            return {
                ...prev,
                [uniqueId]: selectedIndexes.includes(index)
                    ? selectedIndexes.filter((i) => i !== index)
                    : [...selectedIndexes, index],
            };
        } else {
            return {
                ...prev,
                [uniqueId]: [index],  
            };
        }
    });
};

const handleInputChange = (index, value) => {
  const uniqueId = currentAssessment.unique_id;
  setAssessmentData((prev) => {
    const updatedAnswers = [...(prev[uniqueId]?.answers || [])];
    updatedAnswers[index] = value;
    return {
      ...prev,
      [uniqueId]: {
        ...prev[uniqueId],
        answers: updatedAnswers,
      },
    };
  });
};

const formatQuestionText = (text) => {
  const formattedText = text.replace(/<\/?p>/g, '');
  
  return (
    <span
      dangerouslySetInnerHTML={{ __html: formattedText }}
    />
  );
};

const renderQuestionWithInputs = (question) => {
  const parts = question.split(/\[blank (\d+)\]/);
  const uniqueId = currentAssessment.unique_id;
  const status = assessmentData[uniqueId]?.status;
  
  return (
    <p> {/* Flexbox container */}
      {parts.map((part, index) => {
        if (index % 2 === 1) {
          const blankIndex = parseInt(parts[index], 10) - 1;
          let inputStyle = {};

          if (status === "correct") {
            inputStyle = { color: "#00DFC7" };
          } else if (status === "incorrect") {
            inputStyle = { color: "#FF2443" };
          } else {
            inputStyle = { color: "#FF7A00" };
          }

          // Calculate dynamic width based on input value length
          const inputValue = assessmentData[uniqueId]?.answers[blankIndex] || "";
          const calculatedWidth = inputValue.length * 10 + 20;
            const inputWidth = `${Math.min(Math.max(calculatedWidth, 50), 500)}px`;

          return (
            <React.Fragment key={index}>
              <input
               spellCheck="false"
                type="text"
                value={inputValue}
                onChange={(e) => handleInputChange(blankIndex, e.target.value)}
                className={style.blankInput}
                disabled={assessmentData[currentAssessment.unique_id]?.answered}
                style={{ ...inputStyle, width: inputWidth }}
              />
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={index}>
              {formatQuestionText(part)}
            </React.Fragment>
          );
        }
      })}
    </p>
  );
};

const renderQuestionWithDropdowns = (question, options) => {
  const uniqueId = currentAssessment.unique_id;
  const parts = question.split(/(\[dropdown-\d+\])/);

  return (
    <p>
      {parts.map((part, index) => {
        const dropdownMatch = part.match(/\[dropdown-(\d+)\]/);

        if (dropdownMatch) {
          const dropdownId = parseInt(dropdownMatch[1], 10); 
          const dropdownOptions = options.find(option => option.id === dropdownId)?.options || [];

          return (
            <React.Fragment key={index}>
              <select
                value={assessmentData[uniqueId]?.answers?.[dropdownId] || ""}
                onChange={(e) => {
                  const newAnswers = {...(assessmentData[uniqueId]?.answers || {})};
                  newAnswers[dropdownId] = e.target.value;
                  setAssessmentData((prev) => ({
                    ...prev,
                    [uniqueId]: {
                      ...prev[uniqueId],
                      answers: newAnswers,
                    },
                  }));
                }}
                className={style.blankDropdown}
                disabled={assessmentData[currentAssessment.unique_id]?.answered}
              >
                <option disabled value="">
                  Select
                </option>
                {dropdownOptions.map((option, i) => (
                  <option key={i} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </React.Fragment>
          );
        }
        return <React.Fragment key={index}>{formatQuestionText(part)}</React.Fragment>;
      })}
    </p>
  );
};
  

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
  
    const sourceDroppableId = result.source.droppableId;
    const destinationDroppableId = result.destination.droppableId;
    const uniqueId = currentAssessment.unique_id;
  
    setAssessmentData((prev) => {
      const prevAnswers = prev[uniqueId]?.answers || { left: [], right: [] };
  
      let newAnswers = { ...prevAnswers };
  
      if (sourceDroppableId.startsWith("left") && destinationDroppableId.startsWith("left")) {
        const items = Array.from(newAnswers.left);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        newAnswers.left = items;
      } else if (sourceDroppableId.startsWith("right") && destinationDroppableId.startsWith("right")) {
        const items = Array.from(newAnswers.right);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        newAnswers.right = items;
      }
  
      return {
        ...prev,
        [uniqueId]: {
          ...prev[uniqueId],
          answers: newAnswers,
        },
      };
    });
  };
  
  
  const handleArrangeDragEnd = (result) => {
    if (!result.destination) return;

    const uniqueId = currentAssessment.unique_id;
    const items = Array.from(assessmentData[uniqueId]?.answers || []);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setAssessmentData((prev) => ({
      ...prev,
      [uniqueId]: {
        ...prev[uniqueId],
        answers: items,
      },
    }));
  };

  const handleCellValueChange = (rowIndex, colIndex, value, clueID, clueNumber) => {
    const uniqueId = currentAssessment.unique_id;

    setAssessmentData(prevData => {
        const newAnswers = prevData[uniqueId]?.answers.map((row, i) =>
            row.map((cell, j) =>
                i === rowIndex && j === colIndex
                    ? { ...cell, cluevalue: value.toUpperCase(), clueID, clueNumber }
                    : cell
            )
        ) || [];

        return {
            ...prevData,
            [uniqueId]: {
                ...prevData[uniqueId],
                answers: newAnswers,
            },
        };
    });
    const rows = assessmentData[uniqueId]?.answers.length || 0;
    const cols = assessmentData[uniqueId]?.answers[0]?.length || 0;

    let nextRowIndex = rowIndex+ 1;
    let nextColIndex = colIndex ; 

    if (nextRowIndex >= rows) {
        nextColIndex += 1; 
        nextRowIndex = 0; 
    }
    if (nextRowIndex < rows && inputRefs.current[uniqueId]?.[nextRowIndex * cols + nextColIndex]) {
        const nextInputRef = inputRefs.current[uniqueId][nextRowIndex * cols + nextColIndex];
        if (nextInputRef) {
            console.log("Focusing on:", nextInputRef);
            nextInputRef.focus();
        }
    }
};

const getBackgroundColor = (status) => {
  switch (status) {
    case "correct":
      return "#00DFC7";
    case "incorrect":
      return "#FF7C8E";
    default:
      return "transparent";
  }
};
const getHighlightColor = (status) => {
  switch (status) {
    case 'correct':
      return '#00FFC1'; // Color for correct status
    case 'incorrect':
      return '#FF7C8E'; // Color for incorrect status
    default:
      return '#FF7A00'; // Default highlight color
  }
};

const handleWordSelection = () => {
  const selection = window.getSelection();
  const selectedText = selection.toString().trim();
  const uniqueId = currentAssessment.unique_id;

  if (selectedText && assessmentData[uniqueId]) {
      const container = highlightRefs.current[uniqueId];
      const originalContent = assessmentData[uniqueId].originalContent || container.innerHTML;
      const contentWithoutHighlights = originalContent.replace(/<span class="highlighted"[^>]*>(.*?)<\/span>/g, '$1');
      const startIndex = contentWithoutHighlights.indexOf(selectedText);
      
      if (startIndex !== -1) {
          const endIndex = startIndex + selectedText.length;
          const highlightedRegex = new RegExp(`<span class="highlighted"[^>]*>${selectedText}</span>`);
          const isAlreadyHighlighted = highlightedRegex.test(container.innerHTML);
          let newContent;
          if (isAlreadyHighlighted) {
              newContent = contentWithoutHighlights;
          } else {
              const highlightColor = getHighlightColor(assessmentData[uniqueId]?.status);
              const highlightedText = `<span class="highlighted" style="color: ${highlightColor}; white-space: pre-wrap;">${selectedText}</span>`;
              newContent = contentWithoutHighlights.slice(0, startIndex) +
                  highlightedText +
                  contentWithoutHighlights.slice(endIndex);
          }

          setAssessmentData((prev) => ({
              ...prev,
              [uniqueId]: {
                  ...prev[uniqueId],
                  highlightContent: newContent,
                  lastSelectedText: isAlreadyHighlighted ? '' : selectedText,
              },
          }));

          selection.removeAllRanges();
      }
  }
};


  const handleNext = () => {
    setCurrentIndex(currentIndex + 1);
    setValidateClicked(false);
  };

  const handleSkip = () => {
    const uniqueId = currentAssessment?.unique_id;
    if (assessmentData[uniqueId]?.status === 'viewed') {
      setAssessmentData((prev) => ({
        ...prev,
        [uniqueId]: {
          ...prev[uniqueId],
          status: 'skipped',
        },
      }));
    }

    
    if (uniqueId && assessmentData[uniqueId]) {
        const currentAssmnt = assessment[currentIndex];
        let resetAnswers;
        let resetHighlightContent = "";
        let resetLastSelectedText = "";
        let resetAcrossClues = [];
        let resetDownClues = [];
          let highlightMultipleContent=""

        if (currentAssmnt.type === "Match the following" || currentAssmnt.type === "Match the following with image") {
          const filteredLeft = (currentAssmnt.left || []).filter(
            (leftData) => leftData.trim() !== ""
        );
        const filteredRight = (currentAssmnt.right || []).filter(
            (rightData) => rightData.trim() !== ""
        );
            resetAnswers = {
                left: filteredLeft,
                right: filteredRight,
            };
        }  else if (currentAssmnt.type === "Rearrange") {
            resetAnswers =currentAssmnt.options || [];
        } else if (currentAssmnt.type === 'cross word puzzle') {
            resetAnswers = currentAssmnt.options.map(row => (
                row.map(cell => ({
                    clueID: cell.clueID || "",
                    clueNumber: cell.clueNumber,
                    cluevalue: '',
                    disabled: cell.cluevalue === '',
                }))
            ));

            resetAcrossClues = currentAssmnt.left.map(clueObj => ({
                id: clueObj.id,
                number: clueObj.number,
                clue: clueObj.clue,
                answer: ''
            }));
            resetDownClues = currentAssmnt.right.map(clueObj => ({
                id: clueObj.id,
                number: clueObj.number,
                clue: clueObj.clue,
                answer: '' 
            }));
        } else if (currentAssmnt.type === "highlight") {
          resetHighlightContent =currentAssmnt.options.replace(
            /(<[^>]+)style=["'][^"']*color:\s*rgb\(255,\s*122,\s*0\);?[^"']*["']/g,
            '$1'
        ) || ""; 
        resetHighlightContent =resetHighlightContent.replace(/<span[^>]*>(.*?)<\/span>/g, '$1')|| ""; 

          resetLastSelectedText = "";
      }else if (currentAssmnt.type === "highlight_multiple") {
        highlightMultipleContent = currentAssmnt.options.replace(
            /(<[^>]+)style=["'][^"']*color:\s*rgb\(255,\s*122,\s*0\);?[^"']*["']/g,
            '$1'
        );

        highlightMultipleContent = highlightMultipleContent.replace(/<span[^>]*>(.*?)<\/span>/g, '$1');
        resetAnswers = [];
      
    } else if(currentAssmnt.type === "Drag and Drop"){
      const updatedAnswer = currentAssmnt.answer.map(item => {
        return {
            ...item,
            values: item.values.map(() => "") 
        };
    });
    resetAnswers = updatedAnswer;
    } else {
            resetAnswers = [];
        }

        setAssessmentData((prev) => ({
            ...prev,
            [uniqueId]: {
                ...prev[uniqueId],
                answers: resetAnswers,
                highlightContent: resetHighlightContent,
                lastSelectedText: resetLastSelectedText,
                cluesAcross: resetAcrossClues,
                cluesDown: resetDownClues,
                previousRange: null,
                highlightMultipleContent:highlightMultipleContent,
                highlightMultipleRange:[]
            },
        }));
        setSelectedOptions((prev) => {
          const { [uniqueId]: _, ...rest } = prev; 
          return rest;
      });
    }

    // Proceed to next question
    setCurrentIndex((prevIndex) => ( prevIndex + 1));
    setValidateClicked(false);
};

  const handleEndTest = () => {
    if (!finished) {
      setFinishPopup(true); 
    }
  };

  const handleDragStart = (event, option) => {
    event.dataTransfer.setData("text/plain", option); 
  };
  
  const handleDragOver = (event) => {
    event.preventDefault(); 
  };
  
  const handleDrop = (event, colIndex, rowIndex) => {
    event.preventDefault();
    const droppedValue = event.dataTransfer.getData("text/plain"); 
  
    setAssessmentData((prev) => {
      const updatedAnswers = [...prev[currentAssessment.unique_id].answers];
      updatedAnswers[colIndex].values[rowIndex] = droppedValue;
  
      return {
        ...prev,
        [currentAssessment.unique_id]: {
          ...prev[currentAssessment.unique_id],
          answers: updatedAnswers,
        },
      };
    });
  };
  
  
  


  async function assessmentGet() {
    await fetch(`${baseurl}/fetch/daily/weakly/exams`, {
      method: "POST",
      body: JSON.stringify({
        org_id: OrgID,
        user_id: USERID,
        date: currentDate,
        type: "weekly",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          const modifiedData = data.data[0]?.questions.map((question, index) => {
            const baseQuestion = {
              ...question,
              question_no: index + 1,
            };
    
            if (question.type === "Match the following"||question.type === "Match the following with image") {
              return {
                ...baseQuestion,
                draggable: "right",
              };
            } else if (question.type === "multiple choices") {
              const isMultiSelect = question.answer.length > 1;
              return {
                ...baseQuestion,
                isMultiSelect,
              };
            }else if (question.type === "fill in the blanks"||question.type === "select the answers") {
              return {
                ...baseQuestion,
                question: question.question.replace(/<\/p>/g, '</p><br/>'),
              };
            }
            return baseQuestion;
          });
    
          setAssessment(modifiedData);
          setRandomID(data.data[0]?.random_id)
          setAllotedTime(data.data[0]?.alloted_time || 30); 
          setTimeout(() => {
            setLoading(false)
          }, 2000);
        } else {
          setAssessment([]);
          setCurrentAssessment({});
          setTimeout(() => {
            setLoading(false)
          }, 1000);
        }
      });
  }

  async function assessmentValidate(time_taken) {
    const questionId = currentAssessment.id
      ? currentAssessment.id.toString()
      : "";
    let answerToSend;
      if (currentAssessment.type === "Match the following"||currentAssessment.type === "Match the following with image") {
          if (currentAssessment.draggable === "right") {
              answerToSend = assessmentData[currentAssessment.unique_id]?.answers?.right || [];
          } else if (currentAssessment.draggable === "left") {
              answerToSend = assessmentData[currentAssessment.unique_id]?.answers?.left || [];
          }
      }else if(currentAssessment.type === "cross word puzzle"){
        answerToSend = assessmentData[currentAssessment.unique_id]?.answers.map(answerGroup => (
         Array.isArray(answerGroup) ? 
           answerGroup.map(answer => ({
               clueID: answer.clueID,
               clueNumber: answer.clueNumber,
               cluevalue: answer.cluevalue,
           })) :
           answerGroup 
     ));
     }else if(currentAssessment.type === "highlight"){
      let selectedText = assessmentData[currentAssessment.unique_id]?.lastSelectedText || "";
      if (selectedText.endsWith(".")) {
          selectedText = selectedText.slice(0, -1);
      }
      answerToSend = [selectedText] || [];
  }else if (currentAssessment.type === "fill in the blanks") {
      answerToSend = assessmentData[currentAssessment.unique_id]?.answers.map(answer => answer.trim()) || [];
    }else if (currentAssessment.type === "select the answers") {
      const answersObj = assessmentData[currentAssessment.unique_id]?.answers || {};
      answerToSend = Object.values(answersObj).length > 0 ? Object.values(answersObj) : [];
    } 
     else {
          answerToSend = assessmentData[currentAssessment.unique_id]?.answers || [];
      }
    await fetch(`${baseurl}/user/drill/assignment/answers/validation`, {
      method: "POST",
      body: JSON.stringify({
        random_id:randomID,
        organization_ID: OrgID,
        question: currentAssessment.question,
        question_id: questionId,
        user_id: USERID,
        answer: answerToSend,
        question_type: currentAssessment.type,
        options: currentAssessment.type === "highlight"||currentAssessment.type === "highlight_multiple" ? [assessmentData[currentAssessment.unique_id].highlightContent] : currentAssessment.options || [],
        unique_id: currentAssessment.unique_id,
        left: currentAssessment.left ? currentAssessment.left : [],
        right: currentAssessment.right ? currentAssessment.right : [],
        actual_ans:currentAssessment.type === "cross word puzzle"?currentAssessment.options:currentAssessment.answer,
        question_explained:currentAssessment.question_explained,
        time_taken: time_taken.toString(),
        markif_correct:"",
        markif_incorrect: "",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          if(!attended){
            setAttended(true)
          }
          setAssessmentData((prev) => ({
            ...prev,
            [currentAssessment.unique_id]: {
              ...prev[currentAssessment.unique_id],
              status: "attended",
              answered:true,
            },
          }));
        }
        
      });
  }

  async function assessmentSummary() {
    const skippedQuestions = Object.entries(assessmentData)
    .filter(([, value]) => value.status === 'skipped')
    .map(([uniqueId]) => uniqueId); 

  const skippedData = skippedQuestions.map(uniqueId => {
    const assessmentItem = assessment.find(item => item.unique_id === uniqueId);
    const {
      comment_by_learner,
      comment_by_mentor,
      comment_by_other,
      publish_status,
      generation,
      answer,
      options,
      id,
      type,
      ...filteredAssessmentItem 
    } = assessmentItem;

    return {
      ...filteredAssessmentItem, 
      random_id: randomID,
      organization_ID: OrgID,
      user_id: USERID,
      time_taken:"",
      markif_correct:"",
      markif_incorrect: "",
      answer: [],
      actual_ans: assessmentItem.type === "cross word puzzle" ? assessmentItem.options : assessmentItem.answer,
      options: assessmentItem.type === "highlight" ? [] : assessmentItem.options || [],
      question_id:assessmentItem.id,
      question_type:assessmentItem.type,
      left:assessmentItem.left||[],
      right:assessmentItem.right||[]
    };
  });

    await fetch(`${baseurl}/drill/summary`, {
      method: "POST",
      body: JSON.stringify({
        random_id: randomID,
        user_id: USERID,
        skip:skippedData||[]
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if(data.status===true){
          const transformedData = {
            correctAns: data.Correct_ans?.[0]?.Correct_ans || 0,
            incorrectAns: data.Incorrect_ans?.[0]?.Incorrect_ans || 0,
            skippedQuestions: data.Skipped_questions?.[0]?.Skipped_questions || 0,
            totalQuestions: data.Total_questions?.[0]?.Total_questions || 0,
            data: data.data.map((item) => ({
              ...item,
              isExpand: false,
            })),
            percentage: data.percentage?.[0]?.percentage || 0,
          };
          setCarouselData(data?.awards)
          setTimeout(() => {
            if (data?.awards && data.awards.length > 0) {
                setAchievementPopup(true);
            }
        }, 1000);
         setResult(true)
         setSummaryDetail(transformedData)
         setFinishPopup(false)
        }
        else{
          if(timeOuted||timeLeft<1){
            setResult(true)
          }
        }
      });
  }

  useEffect(() => {
    assessmentGet();
  }, [location.state]);

  const handleSelectionChange = (range, oldRange, source) => {
    const quill = quillRef.current.getEditor();
    const uniqueId = currentAssessment.unique_id; 

    if (range && range.length > 0) {
        const selectedText = quill.getText(range.index, range.length).trim();
        const currentData = assessmentData[uniqueId];
        const status = currentData.status;
        const previousRange = currentData.previousRange;
        if (
          previousRange &&
          previousRange.index === range.index &&
          previousRange.length === range.length
        ) {
          quill.formatText(range.index, range.length, 'color', false);
          setAssessmentData((prev) => ({
            ...prev,
            [uniqueId]: {
                ...currentData,
                previousRange: null, 
                lastSelectedText: '', 
                highlightContent: quill.root.innerHTML, 
            },
        }));
        } else {
            if (previousRange) {
                quill.formatText(previousRange.index, previousRange.length, 'color', false);
            }
            const highlightColor = getHighlightColor(status);
            quill.formatText(range.index, range.length, 'color', highlightColor);
            setAssessmentData((prev) => ({
                ...prev,
                [uniqueId]: {
                    ...currentData,
                    previousRange: range, 
                    lastSelectedText: selectedText,
                    highlightContent: quill.root.innerHTML,
                },
            }));
        }
    }
};

const handleMultipleSelectionChange = (range, oldRange, source) => {
  const quill = quillRef.current.getEditor();
  const uniqueId = currentAssessment.unique_id;

  if (range && range.length > 0) {
    let selectedText = quill.getText(range.index, range.length).trim();
    selectedText = selectedText.endsWith(".") ? selectedText.slice(0, -1) : selectedText;
    if (!selectedText) return;

    const currentData = assessmentData[uniqueId];
    const highlightColor = getHighlightColor(currentData.status);
    const currentRanges = currentData.highlightMultipleRange || [];
    const currentAnswers = currentData.answers || [];
    const existingRangeIndex = currentRanges.findIndex(
      (r) => r.index === range.index && r.length === range.length
    );

    if (existingRangeIndex !== -1) {
      quill.formatText(range.index, range.length, "color", false);

      const updatedRanges = [...currentRanges];
      updatedRanges.splice(existingRangeIndex, 1);

      const updatedAnswers = currentAnswers.filter(
        (text) => text !== currentRanges[existingRangeIndex].text
      );

      setAssessmentData((prev) => ({
        ...prev,
        [uniqueId]: {
          ...currentData,
          highlightMultipleRange: updatedRanges,
          highlightMultipleContent: quill.root.innerHTML,
          answers: updatedAnswers,
        },
      }));
    } else {
      const overlappingRanges = currentRanges.filter(
        (r) =>
          r.index < range.index + range.length &&
          range.index < r.index + r.length
      );

      let updatedRanges = [...currentRanges];
      let updatedAnswers = [...currentAnswers];
      overlappingRanges.forEach((overlappingRange) => {
        quill.formatText(
          overlappingRange.index,
          overlappingRange.length,
          "color",
          false
        );

        updatedRanges = updatedRanges.filter(
          (r) => r.index !== overlappingRange.index || r.length !== overlappingRange.length
        );
        updatedAnswers = updatedAnswers.filter(
          (text) => text !== overlappingRange.text
        );
      });
      quill.formatText(range.index, range.length, "color", highlightColor);
      updatedRanges.push({ index: range.index, length: range.length, text: selectedText });
      updatedAnswers.push(selectedText);
      setAssessmentData((prev) => ({
        ...prev,
        [uniqueId]: {
          ...currentData,
          highlightMultipleRange: updatedRanges,
          highlightMultipleContent: quill.root.innerHTML,
          answers: updatedAnswers,
        },
      }));
    }
  }
};

const handleQuillChange = (value) => {
  if (quillRef.current) {
    const quill = quillRef.current.getEditor();
    const container = quill.root.parentElement;
    container.style.border = 'none';  
    container.style.boxShadow = 'none';  
}
  const uniqueId = assessment[currentIndex]?.unique_id;
  setAssessmentData(prev => ({
      ...prev,
      [uniqueId]: {
          ...prev[uniqueId],
          highlightContent: value, 
      },
  }));
};

const handleHighlightMultipleQuillChange = (value) => {
  if (quillRef.current) {
    const quill = quillRef.current.getEditor();
    const container = quill.root.parentElement;
    container.style.border = 'none';  
    container.style.boxShadow = 'none';  
}
  const uniqueId = assessment[currentIndex]?.unique_id;
  setAssessmentData(prev => ({
      ...prev,
      [uniqueId]: {
          ...prev[uniqueId],
          highlightMultipleContent: value, 
      },
  }));
};

useEffect(() => {
  if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      const uniqueId = currentAssessment.unique_id;
      const status = assessmentData[uniqueId]?.status;
      const previousRange = assessmentData[uniqueId]?.previousRange;
      const highlightMultipleRange = assessmentData[uniqueId]?.highlightMultipleRange || [];

      if (quill && status) {
          const highlightColor = getHighlightColor(status);

          if (currentAssessment.type === "highlight" && previousRange) {
              quill.formatText(previousRange.index, previousRange.length, 'color', highlightColor);
          }
          if (currentAssessment.type === "highlight_multiple" && highlightMultipleRange.length > 0) {
              highlightMultipleRange.forEach(range => {
                  quill.formatText(range.index, range.length, 'color', highlightColor);
              });
          }
      }
  }
}, [assessmentData[currentAssessment.unique_id]?.status,]);



useEffect(()=>{ 
  if(quillRef.current){
    const quill = quillRef.current.getEditor();
    const container = quill.root.parentElement;
    container.style.border = 'none';
  }

},[])

  useEffect(() => {
    if (assessment.length > 0) {
      const currentAssmnt = assessment[currentIndex];
      const uniqueId = currentAssmnt?.unique_id;
  
      setCurrentAssessment(currentAssmnt);
  
      if (!assessmentData[uniqueId]) {
        let initialAnswers;
        let initialAcrossClues = [];
        let initialDownClues = [];
        let highlightContent = ""
        let lastSelectedText = ""
        let highlightMultipleContent = ""
  
        if (currentAssmnt.type === "Match the following" ||currentAssmnt.type === "Match the following with image") {
          const filteredLeft = (currentAssmnt.left || []).filter(
            (leftData) => leftData.trim() !== ""
          );
          const filteredRight = (currentAssmnt.right || []).filter(
            (rightData) => rightData.trim() !== ""
          );
          initialAnswers = {
            left: filteredLeft,
            right: filteredRight,
          };
        }else if (currentAssmnt.type === "Rearrange") {
          initialAnswers = currentAssmnt.options || [];
        }else if (currentAssmnt.type === 'cross word puzzle') {
          const grid = currentAssmnt.options.map(row => (
              row.map(cell => ({
                  clueID: cell.clueID || "",
                  clueNumber: cell.clueNumber,
                  cluevalue: '',
                  disabled: cell.cluevalue === '',
              }))
          ));
          initialAnswers = grid;

          initialAcrossClues = currentAssmnt.left.map(clueObj => ({
              id: clueObj.id,
              number: clueObj.number,
              clue: clueObj.clue,
              answer: ''
          }));
          initialDownClues = currentAssmnt.right.map(clueObj => ({
              id: clueObj.id,
              number: clueObj.number,
              clue: clueObj.clue,
              answer: ''
          }));
      }else if (currentAssmnt.type === "highlight") {
       
        highlightContent = currentAssmnt.options.replace(
            /(<[^>]+)style=["'][^"']*color:\s*rgb\(255,\s*122,\s*0\);?[^"']*["']/g,
            '$1'
        );

       
        highlightContent = highlightContent.replace(/<span[^>]*>(.*?)<\/span>/g, '$1');

        lastSelectedText = "";
    } else if (currentAssmnt.type === "highlight_multiple") {
      highlightMultipleContent = currentAssmnt.options.replace(
          /(<[^>]+)style=["'][^"']*color:\s*rgb\(255,\s*122,\s*0\);?[^"']*["']/g,
          '$1'
      );

      highlightMultipleContent = highlightMultipleContent.replace(/<span[^>]*>(.*?)<\/span>/g, '$1');
      initialAnswers = [];
    
  }else if(currentAssmnt.type === "Drag and Drop"){
    const updatedAnswer = currentAssmnt.answer.map(item => {
      return {
          ...item,
          values: item.values.map(() => "") 
      };
  });
  initialAnswers = updatedAnswer;
  } else {
          initialAnswers = [];
        }
  
        setAssessmentData((prev) => ({
          ...prev,
          [uniqueId]: {
              answers: initialAnswers,
              status: "",
              explanations: "",
              cluesAcross: initialAcrossClues, 
              cluesDown: initialDownClues, 
              highlightContent:highlightContent,
              lastSelectedText:lastSelectedText,
              previousRange: null,
              highlightMultipleContent:highlightMultipleContent,
              highlightMultipleRange:[]
          },
        }));
      } else if (!assessmentData[uniqueId]?.status) {
        setAssessmentData((prev) => ({
          ...prev,
          [uniqueId]: {
            ...prev[uniqueId],
            status: 'viewed',
          },
        }));
      }
    }
  }, [currentIndex, assessment, assessmentData]);

  useEffect(() => {
    if (allotedTime !== null && !finished) {  
      setTimeLeft(allotedTime * 60);  
      setTimeRunning(true) 
      timerRef.current = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime > 0) {
            return prevTime - 1;
          } else {
            clearInterval(timerRef.current);
            setTimeRunning(false)
            setTimeOuted(true);
            if (!finished) {
              assessmentSummary();
            }
            return 0;
          }
        });
      }, 1000);
  
      return () => clearInterval(timerRef.current);
    }
  }, [allotedTime, finished]);

  useEffect(() => {
    if (currentAssessment) {
        if (!inputRefs.current[currentAssessment.unique_id]) {
            inputRefs.current[currentAssessment.unique_id] = [];
        }
    }
  }, [currentAssessment]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (timeRunning && !finished) {
        event.preventDefault();
        event.returnValue = ''; 
      }
    };
    const handlePopState = (event) => {
      if (timeRunning && !finished) {
        event.preventDefault();
        setFinishPopup(true);
      }
    };
    window.history.pushState(null, '', window.location.href);
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [timeRunning, finished]);

  useEffect(() => {
    if (currentAssessment) {
        if (!inputRefs.current[currentAssessment.unique_id]) {
            inputRefs.current[currentAssessment.unique_id] = [];
        }
    }
  }, [currentAssessment]);

  useEffect(() => {
    const uniqueId = currentAssessment.unique_id;
    const container = highlightRefs.current[uniqueId];
  
    if (container) {
      const spans = container.querySelectorAll('.highlighted');
      spans.forEach((span) => {
        const newColor = getHighlightColor(assessmentData[uniqueId]?.status);
        span.style.color = newColor; 
      });
    }
  }, [assessmentData[currentAssessment.unique_id]?.status]);
  
  
  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={error}
        sx={{ width: "25rem" }}
        autoHideDuration={3000}
        onClose={() => {
          setError(false);
        }}
      >
        <Alert
          severity="error"
          variant="filled"
          sx={{ width: "100%", fontSize: "1rem" }}
        >
          Please enter answer..
        </Alert>
      </Snackbar>
      <div className={style.assessmentSection}>
        <div className={style.header}>
          <div className={style.headerText}>
            <span>Weekly Workout</span>
          </div>
          <div className={style.headerIcon}>
            <img src={WorkOut} alt="calendar" />
          </div>
        </div>
        {loading?
        <Loader/>
        :
        <>
        {currentAssessment.question_no &&!result?
        <div className={style.progressBarSection}>
          <div
            className={style.progressBar}
            style={{
              width: "95%",
              backgroundColor: "#8E88903D",
              borderRadius: "2rem",
            }}
          >
            <div
              style={{
                width: `${progressPercentage}%`,
                backgroundColor: "#595BDB",
                height: "12px",
                transition: "width 0.5s ease-in-out",
                borderRadius: "1rem",
              }}
            ></div>
          </div>
          <div className={style.progressBarCount}>
          <p>{`${attendedCount}/${totalCount}`}</p>
          </div>
        </div>
          :""}
        {currentAssessment.question_no ? (
          <div className={style.body}>
            <div className={style.bodyLeft}>
            {result ? (
                <>
                  <div className={style.resultSection}>
                        {timeOuted ? (
                          <>
                            <div className={style.resultSectionHeder}>
                              <h4 style={{color:"#FF2443"}}>Time's Up!</h4>
                              <p>You ran out of time. Don't worry, you can try again tomorrow. Practice makes perfect!</p>
                            </div>
                            <div className={style.resultSectionImage}>
                              <img src={ClockImage} alt="Time Out" /> 
                            </div>
                            <div className={style.resultSectionBottomContent}>
                            {summaryDetail.correctAns&&(
                                <>
                                  <p>You scored</p>
                                  <h4>{summaryDetail.correctAns}/{summaryDetail?.totalQuestions || 0}</h4>
                                </>
                                )}
                            </div>
                          </>
                        ) : (
                          summaryDetail?.percentage!== undefined && (
                            <>
                              {summaryDetail.percentage >= 85 ? (
                                <>
                                  <div className={style.resultSectionHeder}>
                                    <h4 style={{color:"#D09B00"}}>Congratulations! </h4>
                                    <p>You’ve done an excellent job in the test. Keep up the great work.</p>
                                  </div>
                                  <div className={style.resultSectionImage}>
                                    <img src={TrophyImage} alt="Winner" />
                                  </div>
                                </>
                              ) : summaryDetail.percentage >= 60 ? (
                                <>
                                  <div className={style.resultSectionHeder}>
                                    <h4 style={{color:"#00CBF4"}}>Well Done! </h4>
                                    <p>You’ve performed well in the test. Keep up the good work.</p>
                                  </div>
                                  <div className={style.resultSectionImage}>
                                    <img src={SecondTrophyImage} alt="Winner" />
                                  </div>
                                </>
                              ) : summaryDetail.percentage >= 40 ? (
                                <>
                                  <div className={style.resultSectionHeder}>
                                    <h4 style={{color:"#FF8800"}}>Good effort!</h4>
                                    <p>There’s room for improvement, but you’re on the right track. Keep practising.</p>
                                  </div>
                                  <div className={style.resultSectionImage}>
                                    <img src={ThirdTrophyImage} alt="Average" />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className={style.resultSectionHeder}>
                                    <h4 style={{color:"#FF2443"}}>Don't give up!</h4>
                                    <p>With consistent practice, you can achieve your goals.</p>
                                  </div>
                                  <div className={style.resultSectionImage}>
                                    <img src={FourthTrophyImage} alt="Try Again" />
                                  </div>
                                </>
                              )}
                              <div className={style.resultSectionBottomContent}>
                                <p>You scored</p>
                                <h4>{summaryDetail.correctAns}/{summaryDetail?.totalQuestions || 0}</h4>
                              </div>
                            </>
                          )
                        )}
                      </div>
                      {summaryDetail&&Object.keys(summaryDetail).length>0&&
                  <div className={style.finalAnswerSection}>
                    <div className={style.finalAnswerSectionHeader}>
                      <p>Answers:</p>
                    </div>
                    <div className={style.finalAnswerSectionBody}>
                    <div className={style.finalAnswerSectionBody}>
                    {summaryDetail.data?.map((item, index) =>{
                      const modifiedQuestionExplained = item.question_explained.replace(
                        /<img/g,
                        '<img style="width:100px; height:auto;"'
                      );
                      const modifiedQuestion = item.question.replace(
                        /<img/g,
                        '<img style="width:200px; height:auto;"'
                      );
                      return (
                      <div key={index} className={`${style.finalAnswerBodyData} ${
                        expandedIndex === index ? style.expanded : ""
                      }`}>
                        <div className={style.answerBodyHeader}>
                          <div className={style.answerBodyHeaderLeft}>
                            {item.answer_is==="Correct" ? (
                              <FaCheck style={{ color: "#00DFC7" }} />
                            ) : (
                              <IoMdClose style={{ color: "#FF7C8E" }} />
                            )}
                            <span>{index + 1}.</span>
                            {item.question_type === "cross word puzzle" ? (
                                  item.answer && item.answer.length > 0 ? (
                                    <div className={style.crossWordPuzzle}>
                                      {item.answer.map((row, rowIndex) => (
                                        <div key={rowIndex} className={style.row}>
                                          {row.map((cell, colIndex) => {
                                            const disabled = !Array.isArray(cell.clueID) || cell.clueID.length === 0;
                                            return (
                                              <div key={`${rowIndex}-${colIndex}`} className={style.cellContainer}>
                                                {!disabled && (
                                                  <input
                                                    type="text"
                                                    maxLength="1"
                                                    className={style.activeInput}
                                                    value={cell.cluevalue || ""}
                                                    readOnly
                                                    disabled={cell.disabled}
                                                  />
                                                )}
                                                {cell.clueNumber && (
                                                  <div className={style.clueNumber}>{cell.clueNumber}</div>
                                                )}
                                              </div>
                                            );
                                          })}
                                        </div>
                                      ))}
                                    </div>
                                  ) : (
                                    <p>Not attended</p>
                                  )
                                ) : item.question_type === "Match the following" ? (
                                  item.answer && item.answer.length > 0 ? (
                                    <ul style={{listStyleType:'none'}}>
                                      {item.answer.map((answer, i) => (
                                        <p key={i}>
                                          {answer}
                                        </p>
                                      ))}
                                    </ul>
                                  ) : (
                                    <p>Not attended</p>
                                  )
                                ): item.question_type === "Drag and Drop" ? (
                                  item.answer &&
                                    item.answer.length > 0 &&
                                    item.answer.some((col) => col.values.some((value) => value !== "")) ? (
                                    <div className={style.dragContainer}>
                          
                                            <div className={style.dragTable}>
                                          <table className={style.table}>
                                            <thead>
                                              <tr>
                                                {item.answer.map((col, colIndex) => (
                                                  <th key={colIndex} className={style.tableHeader}>
                                                    {col.header || " "} 
                                                  </th>
                                                ))}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {[...Array(item.answer[0].values.length || 0)].map((_, rowIndex) => (
                                                <tr key={rowIndex}>
                                                  {item.answer.map((col, colIndex) => (
                                                    <td key={`${colIndex}-${rowIndex}`}
                                                    className={style.tableCell}
                                                    >
                                                      {col.values[rowIndex] ? (
                                                        <div className={style.cardInCell}>
                                                          <span className={style.cardText}>{col.values[rowIndex]}</span>
                                                        </div>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </td>
                                                  ))}
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </div>

                                          </div>
                                  ) : (
                                    <p>Not attended</p>
                                  )
                                )   : (
                                  item.answer && item.answer.length > 0 ? (
                                    <p>{item.answer.join(", ")}</p>
                                  ) : (
                                    <p>Not attended</p>
                                  )
                                )}
                          </div>
                          <div className={style.answerBodyHeaderRight} onClick={() => handleToggle(index)}>
                          {expandedIndex === index ? <FaChevronUp /> : <FaChevronDown />}
                          </div>
                        </div>
                        {expandedIndex === index &&  (
                          <div className={style.answerBodyData}>
                            <div className={style.answerBodyQuestion}>
                              <h4>Question</h4>
                              {item.question_type === "fill in the blanks" ? (
                                        <div className={style.inputWrapper}>
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: item.question.split(/\[blank \d+\]/).map((part, partIndex, partsArray) => {
                                                const inputHTML = partIndex < partsArray.length - 1 
                                                  ? `<input type="text" readonly style="border:none; border-bottom:1px solid; width:60px; margin:0 5px;" />`
                                                  : "";
                                                return part + inputHTML;
                                              }).join(""),
                                            }}
                                          />
                                        </div>
                                      ):item.question_type === "select the answers" ? (
                                        <div className={style.inputWrapper}>
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: item.question.split(/\[dropdown-\d+\]/).map((part, partIndex, partsArray) => {
                                                if (partIndex < partsArray.length - 1) {
                                                  const dropdownOptions = item.options?.[partIndex]?.options || [];
                                                  const dropdownHTML = `
                                                   <select style="border: 1px solid #ccc; padding: 5px; margin: 0 5px;">
                                                      <option value="">Select an option</option>
                                                      ${dropdownOptions
                                                        .map(option => `<option disabled value="${option}">${option}</option>`)
                                                        .join("")}
                                                    </select>
                                                  `;
                                                  return part + dropdownHTML; 
                                                }
                                                return part;
                                              }).join(""),
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <p dangerouslySetInnerHTML={{ __html: modifiedQuestion }}></p>
                                      )}
                                      

        
                              {item.question_type === "multiple choices" && item.options && item.options.length > 0 && (
                                    <div className={style.answerBodyOptions}>
                                      
                                      <ul>
                                        {item.options.map((option, optionIndex) => (
                                          <li key={optionIndex}>{option}</li>
                                        ))}
                                      </ul>
                                    </div>
                                  )}
                                  {item.question_type === "Rearrange" && item.options && item.options.length > 0 && (
                                      <div className={style.answerBodyOptions}>
                                        
                                        <ul>
                                          {item.options.filter(option => option.trim() !== "").map((option, optionIndex) => (
                                            <li key={optionIndex}>{option}</li>
                                          ))}
                                        </ul>
                                      </div>
                                    )}
                                    {item.question_type === "highlight" && item.options&& (
                                      <div className={style.answerBodyOptions}>
                                        
                                        <p>
                                        {item.options && item.options.length > 0 && typeof item.options[0] === 'string'
                                          ? item.options[0]
                                              .replace(/<p>/g, '')
                                              .replace(/<\/p>/g, '')
                                              .replace(/(<[^>]+)style=["'][^"']*color:\s*rgb\(255,\s*122,\s*0\);?[^"']*["']/g, '$1')
                                              .replace(/<span[^>]*>(.*?)<\/span>/g, '$1')
                                              .replace(/&nbsp;$/, '')
                                          : null}
                                        </p>
                                      </div>
                                    )}
                                  {item.question_type === "Match the following" && item.left && item.left.length > 0 && (
                                    <div className={style.answerBodyMatch}>
                                      {item.left.map((leftItem, index) => (
                                        <div key={index} className={style.matchRow}>
                                          <div className={style.matchRowCardLeft}>
                                            <p>{leftItem}</p>
                                          </div>
                                          <div className={style.matchRowCardRight}>
                                            <p>{item.right && item.right[index]}</p>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                   {item.question_type === "Match the following with image" && item.left && item.left.length > 0 && (
                                      <div className={style.answerBodyMatch}>
                                        {item.left.map((leftItem, index) => (
                                          <div key={index} className={style.matchRow}>
                                            <div className={style.matchRowImageCard}>
                                            <img src={leftItem} alt={`Option ${index + 1}`} width="200px" />
                                            </div>
                                            <div className={style.matchRowCardRight}>
                                              <p>{item.right && item.right[index]}</p>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    )}
                                    {item.question_type === "Drag and Drop" && item.options && item.options.length > 0 && (
                                      <div className={style.dragContainer}>
                                        <div className={style.dragOptionContainer}>
                                          {item.options.map((option, index) => {
                                            return(
                                              <div key={index}
                                              className={style.dragCard}
                                              
                                              >
                                                <p>{option}</p>
                                              </div>
                                            )
                                          })}
                                        </div>
                            

                                      </div>

                                    )}
                                  </div>
                                  {item.answer_is!=="Correct"?
                            <div className={style.answerBodyCorrectAnswer}>
                              <h4>Correct answer</h4>
                              {item.question_type === "Match the following" && item.left && item.left.length > 0 ? (
                                  <div className={style.answerBodyMatch}>
                                    {item.left.map((leftItem, index) => (
                                      <div key={index} className={style.matchRow}>
                                        <div className={style.matchRowCardLeft}>
                                          <p>{leftItem}</p>
                                        </div>
                                        <div className={style.matchRowCardRight}>
                                          <p>{item.actual_ans && item.actual_ans[index]}</p>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ): item.question_type === "fill in the blanks" ? (
                                  <div>
                                    {item.actual_ans && item.actual_ans.length > 0 ? (
                                      item.actual_ans.map((answerObj, answerIndex) => (
                                        <p key={answerIndex}>
                                         
                                         {Object.entries(answerObj).map(([key, value]) => (
                                                <span key={key}>
                                                  {value}
                                                  <br />
                                                </span>
                                              ))}
                                        </p>
                                      ))
                                    ) : (
                                      <p>No correct answers available</p>
                                    )}
                                  </div>
                                ) :item.question_type === "Match the following with image" && item.left && item.left.length > 0 ?(
                                  <div className={style.answerBodyMatch}>
                                    {item.left.map((leftItem, index) => (
                                      <div key={index} className={style.matchRow}>
                                        <div className={style.matchRowImageCard}>
                                        <img src={leftItem} alt={`Option ${index + 1}`} width="200px" />
                                        </div>
                                        <div className={style.matchRowCardRight}>
                                          <p>{item.right && item.right[index]}</p>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ): item.question_type === "select the answers" ? (
                                  <div>
                                    {item.actual_ans && item.actual_ans.length > 0 ? (
                                      item.actual_ans.map((answerObj, answerIndex) => (
                                        <p key={answerIndex}>
                                         {Object.values(answerObj).join(", ")}
                                        </p>
                                      ))
                                    ) : (
                                      <p>No correct answers available</p>
                                    )}
                                  </div>
                                ) :item.question_type === "Rearrange" ? ( 
                                  <div>
                                    {item.actual_ans.length > 0 ? (
                                      <div  className={style.reArrangeContainer}>
                                        {item.actual_ans[0] && Object.entries(item.actual_ans[0]).map(([key, value]) => (
                                          <p className={style.reArrangeAnswerCard} key={key}>{value}</p>
                                        ))}
                                      </div>
                                    ) : (
                                      <p>No correct answers available</p>
                                    )}
                                  </div>
                                ) :item.question_type === "cross word puzzle" ? ( 
                                  <div>
                                    <p style={{textDecoration:"underLine"}}>Across</p>
                                    {item.left.length > 0 ? (
                                      <div>
                                        {item.left.map(( value,i) => (
                                          <p key={i}>{value.number}.{value.answer}</p>
                                        ))}
                                      </div>
                                    ) : (
                                      <p>No correct answers available</p>
                                    )}
                                    <p style={{textDecoration:"underLine"}}>Down</p>
                                    {item.right.length > 0 ? (
                                      <div>
                                        {item.right.map(( value,i) => (
                                          <p key={i}>{value.number}.{value.answer}</p>
                                        ))}
                                      </div>
                                    ) : (
                                      <p>No correct answers available</p>
                                    )}
                                  </div>
                                ) : item.question_type === "Drag and Drop" ? (
                                  item.actual_ans&&
                                    item.actual_ans.length > 0 &&
                                    item.actual_ans.some((col) => col.values.some((value) => value !== "")) ? (
                                    <div className={style.dragContainer}>
                          
                                            <div className={style.dragTable}>
                                          <table className={style.table}>
                                            <thead>
                                              <tr>
                                                {item.actual_ans.map((col, colIndex) => (
                                                  <th key={colIndex} className={style.tableHeader}>
                                                    {col.header || " "} 
                                                  </th>
                                                ))}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {[...Array(item.actual_ans[0].values.length || 0)].map((_, rowIndex) => (
                                                <tr key={rowIndex}>
                                                  {item.actual_ans.map((col, colIndex) => (
                                                    <td key={`${colIndex}-${rowIndex}`}
                                                    className={style.tableCell}
                                                    >
                                                      {col.values[rowIndex] ? (
                                                        <div className={style.cardInCell}>
                                                          <span className={style.cardText}>{col.values[rowIndex]}</span>
                                                        </div>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </td>
                                                  ))}
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        </div>

                                          </div>
                                  ) : (
                                    <p>Not answers available</p>
                                  )
                                )    : (
                                  item.actual_ans && item.actual_ans.length > 0 ? (
                                    <div>
                                      {item.actual_ans.map((answer, answerIndex) => (
                                        <p key={answerIndex}>{answer}</p>
                                      ))}
                                    </div>
                                  ) : (
                                    <p>No answers available</p>
                                  )
                                )}
                               
                               <div className={style.answerBodyExplanation}>
                                  <h4>Explanation</h4>
                                  <p
                                dangerouslySetInnerHTML={{
                                  __html: modifiedQuestionExplained,
                                }}
                              ></p>
                                 </div>
                            </div>
                            :''}
                          </div>
                        )}
                      </div>
                    )})}
                  </div>
                    </div>
                  </div>
}
                </>
              ) : (
                <div className={style.questionSection}>
                  <div className={style.question}>
                    <span>{currentAssessment.question_no}.</span>
                    {currentAssessment.type === "fill in the blanks" ? (
                      <div className={style.inputWrapper}>
                        {renderQuestionWithInputs(
                          currentAssessment.question,
                        )}
                    </div>
                    ) : currentAssessment.type === "select the answers" ? (
                      <div className={style.dropDownWrapper}>
                        {renderQuestionWithDropdowns(
                          currentAssessment.question,
                          currentAssessment.options
                        )}
                      </div>
                    ): (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: currentAssessment.question,
                        }}
                      ></p>
                    )}
                  </div>
                  <div className={style.questionBody}>
                    {currentAssessment.type === "ordinary" ? (
                      <div className={style.ordinaryContainer}>
                        <div className={style.ordinarySection}>
                          <div className={style.ordinarySectionRight}>
                          <input
                          spellCheck="false"
                            disabled={assessmentData[currentAssessment.unique_id]?.answered}
                            onChange={(e) => {
                              const uniqueId = currentAssessment.unique_id;
                              setAssessmentData((prev) => ({
                                ...prev,
                                [uniqueId]: {
                                  ...prev[uniqueId],
                                  answers: [e.target.value],
                                },
                              }));
                            }}
                            value={assessmentData[currentAssessment.unique_id]?.answers[0] || ""}
                          />
                          </div>
                        </div>
                      </div>
                    ) : currentAssessment.type === "multiple choices" ? (
                      <div className={style.multipleChoiceContainer}>
                      {currentAssessment.options?.filter(option => option.trim() !== "").map((option, index) => {
                         const selectedIndexes = selectedOptions[currentAssessment.unique_id] || [];
                         const isSelected = selectedIndexes.includes(index);
             
                         let optionClass = "";
                         if (isSelected) {
                             optionClass =
                                 style.selected;
                         } else {
                             optionClass = style.unselected;
                         }
             
                        return (
                          <div
                            key={index}
                            onClick={() =>{
                              if(assessmentData[currentAssessment.unique_id]?.answered!==true){
                                handleOptionClick(option, index)}}
                               }
                            className={`${style.multipleChoiceAnswer} ${optionClass}`}
                          >
                            <p>{option}</p>
                          </div>
                        );
                      })}
                    </div>
                    ) : currentAssessment.type === "Match the following" ? (
                      <div className={style.matchFollowingContainer}>
                      <DragDropContext onDragEnd={handleOnDragEnd}>
                        {(assessmentData[currentAssessment.unique_id]?.answers?.left || []).filter(leftData => leftData.trim() !== "").map((leftData, i) => {
                           return (
                          <React.Fragment key={i}>
                            <Droppable droppableId={`left-${i}`}>
                              {(provided) => (
                                <div
                                  className={style.matchFollowingLeft}
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  <Draggable
                                    draggableId={`left-${i}`}
                                    index={i}
                                    isDragDisabled={
                                      currentAssessment.draggable === "right" ||
                                      assessmentData[currentAssessment.unique_id]?.answered
                                    }
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={style.matchFollowingCard}
                                      >
                                        <p>{leftData}</p>
                                      </div>
                                    )}
                                  </Draggable>
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                  
                            <Droppable droppableId={`right-${i}`}>
                              {(provided) => (
                                <div
                                  className={style.matchFollowingRight}
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  <Draggable
                                    draggableId={`right-${i}`}
                                    index={i}
                                    isDragDisabled={
                                      currentAssessment.draggable === "left" ||
                                      assessmentData[currentAssessment.unique_id]?.answered
                                    }
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={style.matchFollowingCard}
                                      >
                                        <p>{(assessmentData[currentAssessment.unique_id]?.answers?.right || [])[i]}</p>
                                      </div>
                                    )}
                                  </Draggable>
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </React.Fragment>
                        )})}
                      </DragDropContext>
                    </div>
                    ) : currentAssessment.type === "Match the following with image" ? (
                      <div className={style.matchFollowingContainer}>
                      <DragDropContext onDragEnd={handleOnDragEnd}>
                        {(assessmentData[currentAssessment.unique_id]?.answers?.left || []).filter(leftData => leftData.trim() !== "").map((leftData, i) => {
                           const matchValue = assessmentData[currentAssessment.unique_id]?.match?.[i];
                           let cardClassName;
                           if (matchValue === "1") {
                             cardClassName = style.correctCard;
                           } else if (matchValue === "0") {
                             cardClassName = style.errorCard;
                           } else {
                             cardClassName = style.defaultCard;
                           }
                           return (
                          <React.Fragment key={i}>
                            <Droppable droppableId={`left-${i}`}>
                              {(provided) => (
                                <div
                                  className={style.matchFollowingLeft}
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  <Draggable
                                    draggableId={`left-${i}`}
                                    index={i}
                                    isDragDisabled={
                                      currentAssessment.draggable === "right" ||
                                      assessmentData[currentAssessment.unique_id]?.answered
                                    }
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={`${style.matchFollowingImageCard} ${cardClassName}`}
                                      >
                                        <img src={leftData} alt={`Option ${i + 1}`}  />
                                      </div>
                                    )}
                                  </Draggable>
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                  
                            <Droppable droppableId={`right-${i}`}>
                              {(provided) => (
                                <div
                                  className={style.matchFollowingRight}
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  <Draggable
                                    draggableId={`right-${i}`}
                                    index={i}
                                    isDragDisabled={
                                      currentAssessment.draggable === "left" ||
                                      assessmentData[currentAssessment.unique_id]?.answered
                                    }
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={`${style.matchFollowingCard} ${cardClassName}`}
                                      >
                                        <p>{(assessmentData[currentAssessment.unique_id]?.answers?.right || [])[i]}</p>
                                      </div>
                                    )}
                                  </Draggable>
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </React.Fragment>
                        )})}
                      </DragDropContext>
                    </div>
                    ) : currentAssessment.type === "Rearrange" ? (
                      <DragDropContext onDragEnd={handleArrangeDragEnd}>
                      <Droppable droppableId="rearrange" direction="horizontal">
                        {(provided) => (
                          <div
                            className={style.reArrangeContainer}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {assessmentData[currentAssessment.unique_id]?.answers.map((option, index) => (
                              <Draggable
                                key={index}
                                draggableId={`option-${index}`}
                                index={index}
                                isDragDisabled={assessmentData[currentAssessment.unique_id]?.answered}
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={style.reArrangeCard}
                                  >
                                    <p>{option}</p>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                      
                    </DragDropContext>
                    ) : currentAssessment.type === "cross word puzzle"? (
                      <div className={style.crossWordContainer}>
                      <div className={style.crossWordPuzzle}>
                      {assessmentData[currentAssessment.unique_id]?.answers.map((row, rowIndex) => (
                        <div key={rowIndex} className={style.row}>
                          {row.map((cell, colIndex) => (
                            <div key={`${rowIndex}-${colIndex}`} className={style.cellContainer}>
                              {!cell.disabled && (
                                <input
                                  type="text"
                                  maxLength="1"
                                  className={ style.activeInput}
                                  value={cell.value}
                                  onChange={(e) => handleCellValueChange(rowIndex, colIndex, e.target.value, cell.clueID, cell.clueNumber)}
                                  disabled={cell.disabled}
                                  ref={el => {
                                    if (el) {
                                        if (!inputRefs.current[currentAssessment.unique_id]) {
                                            inputRefs.current[currentAssessment.unique_id] = [];
                                        }
                                        inputRefs.current[currentAssessment.unique_id][rowIndex * (assessmentData[currentAssessment.unique_id]?.answers[0]?.length || 0) + colIndex] = el;
                                    }
                                }}
                                />
                              )}
                              {cell.clueNumber && (  
                                <div className={style.clueNumber}>{cell.clueNumber}</div>
                              )}
                            </div>
                          ))}
                        </div>
                        ))}
                    </div>
                    <div className={style.PuzzleQuestion}>
                      <div className={style.QuestionAcross}>
                      <h4>Across</h4>
                      {assessmentData[currentAssessment.unique_id]?.cluesAcross.map((clueObj, index) => (
                        <div key={index} className={style.QuestionAcrossInputContainer}>
                          <span>{clueObj.number}.{clueObj.clue} </span>
                          
                        </div>
                      ))}
                      
                      </div>
                      <div className={style.QuestionDown}>
                      <h4>Down</h4>
                      {assessmentData[currentAssessment.unique_id]?.cluesDown.map((clueObj, index) => (
                          <div key={index} className={style.QuestionDownInputContainer}>
                            <span>{clueObj.number}.{clueObj.clue} </span>
                            
                          </div>
                        ))}
                        
                        </div>
                    </div>
                      </div>
                    ):currentAssessment.type === "Rewrite" ? (
                      <div className={style.ordinaryContainer}>
                        <div className={style.ordinarySection}>
                          <div className={style.rewriteSectionRight}>
                          <OutlinedInput
                            
                                    spellCheck="false"
                                    disabled={assessmentData[currentAssessment.unique_id]?.answered}
                                    multiline
                                   maxRows={3}
                                   sx={{
                                    backgroundColor: getBackgroundColor(
                                      assessmentData[currentAssessment.unique_id]?.status
                                    ),
                                    borderRadius: "25px",
                                    fontFamily: "'Urbane Extra', sans-serif",
                                    fontSize: "16px",
                                    fontWeight: 300,
                                    lineHeight: "32px",
                                    textAlign: "left",
                                    color: "#000",
                                    padding: ".5rem",
                                    outline: "none",
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      borderColor: "#898890",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                      borderColor: "#898890",
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                      borderColor: "#FF7A00",
                                    },
                                    "& .MuiOutlinedInput-input	": {
                                      fontFamily: "Urbane",
                                      fontSize: "16px",
                                      fontWeight: 300,
                                      lineHeight: "40px",
                                      textAlign: "left",
                                      color: "#000",
                                    },
                                  }}
                                    onChange={(e) => {
                                      const uniqueId = currentAssessment.unique_id;
                                      setAssessmentData((prev) => ({
                                        ...prev,
                                        [uniqueId]: {
                                          ...prev[uniqueId],
                                          answers: [e.target.value],
                                        },
                                      }));
                                    }}
                                    value={assessmentData[currentAssessment.unique_id]?.answers[0] || ""}
                                  />
                          </div>
                        </div>
                      </div>
                    ):currentAssessment.type === "highlight" ? (
                      <div className={style.highlightContainer}>
                        <ReactQuill
                          ref={quillRef}
                          value={assessmentData[currentAssessment?.unique_id]?.highlightContent || ""}
                          onChange={handleQuillChange}
                          onChangeSelection={handleSelectionChange} 
                          modules={{
                              toolbar: false,
                          }}
                          style={{
                            width: "100%",
                            maxHeight: "200px",
                            border: "none !important",
                            pointerEvents: assessmentData[currentAssessment.unique_id]?.answered
                                ? "none"
                                : "auto", // Disable mouse interactions
                            userSelect: assessmentData[currentAssessment.unique_id]?.answered
                                ? "none"
                                : "text", // Disable text selection
                        }}
                          readOnly={true} 
                          className={style.customQuill}
                      />
  
                      </div>
                    ):currentAssessment.type === "highlight_multiple" ? (
                      <ReactQuill
                      disabled={assessmentData[currentAssessment.unique_id]?.answered}
                      ref={quillRef}
                      value={assessmentData[currentAssessment?.unique_id]?.highlightMultipleContent || ""}
                      onChange={handleHighlightMultipleQuillChange}
                      onChangeSelection={handleMultipleSelectionChange} 
                      modules={{
                          toolbar: false,
                      }}
                      style={{
                        width: "100%",
                        maxHeight: "200px",
                        border: "none !important",
                        pointerEvents: assessmentData[currentAssessment.unique_id]?.answered
                            ? "none"
                            : "auto", // Disable mouse interactions
                        userSelect: assessmentData[currentAssessment.unique_id]?.answered
                            ? "none"
                            : "text", // Disable text selection
                    }}
                      readOnly={true} 
                      className={style.customQuill}
                  />

                    ):currentAssessment.type === "Drag and Drop" ? (
                      <div className={style.dragContainer}>
                        <div className={style.dragOptionContainer}>
                          {currentAssessment.options?.filter(option => option.trim() !== "").map((option, index) => {
                            return(
                              <div key={index}
                               className={style.dragCard}
                               draggable={!assessmentData[currentAssessment.unique_id]?.answered} 
                                 onDragStart={(event) =>
                                   !assessmentData[currentAssessment.unique_id]?.answered && handleDragStart(event, option)
                                 }
                               >
                                <p>{option}</p>
                              </div>
                            )
                          })}
                        </div>
                        <div className={style.dragTable}>
                      <table className={style.table}>
                        <thead>
                          <tr>
                            {assessmentData[currentAssessment.unique_id]?.answers.map((col, colIndex) => (
                              <th key={colIndex} className={style.tableHeader}>
                                {col.header || " "} 
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {[...Array(assessmentData[currentAssessment.unique_id]?.answers[0].values.length || 0)].map((_, rowIndex) => (
                            <tr key={rowIndex}>
                              {assessmentData[currentAssessment.unique_id]?.answers.map((col, colIndex) => (
                                <td key={`${colIndex}-${rowIndex}`}
                                className={style.tableCell}
                                onDragOver={(event) =>
                                  !assessmentData[currentAssessment.unique_id]?.answered && handleDragOver(event)
                                } 
                                onDrop={(event) =>
                                  !assessmentData[currentAssessment.unique_id]?.answered && handleDrop(event, colIndex, rowIndex)
                                } 
                                >
                                  {col.values[rowIndex] ? (
                                    <div className={style.cardInCell}>
                                      <span className={style.cardText}>{col.values[rowIndex]}</span>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                      </div>

                    ): (
                      ""
                    )}
                  </div>
                  <div className={style.questionButtonSection}>
                  <div className={style.questionButtonLeft}>
                      {currentAssessment.type === "Match the following"|| currentAssessment.type === "Drag and Drop" ? (
                        <span>*Drag the boxes on the right to drop them next to the correct match on the left.</span>
                      ) : (
                        ""
                      )}
                     
                    </div>
                    <div className={style.questionButtonRight}>
                    {assessmentData[currentAssessment.unique_id]?.answered ?"":
                      <p
                        onClick={() => {
                          if(currentIndex===assessment.length - 1){
                           handleEndTest();
                          }
                          else{
                           handleSkip();
                          }
                         
                         }}
                      >
                        Skip
                      </p>
                        }
                     
                      {assessmentData[currentAssessment.unique_id]?.answered ? (
                       <>
                         {currentIndex!==assessment.length-1?
                          <button
                            onClick={() => handleNext()}
                            disabled={currentIndex === assessment.length - 1}
                            style={{ background: "#00CBF480" }}
                          >
                            Next
                          </button>
                          :null}
                       </>
                      ) : (
                        <button
                          disabled={validateClicked||assessmentData[currentAssessment.unique_id]?.answered}
                          onClick={() => {
                          const uniqueId = currentAssessment.unique_id;
                          const answers = assessmentData[uniqueId]?.answers || [];

                          if (
                            currentAssessment.type === "Match the following" ||
                            currentAssessment.type === "Match the following with image"
                          ) {
                            const rightFromAssessment = currentAssessment.right.filter(right => right.trim() !== "") || [];
                            const rightFromData = answers?.right || [];

                            const areRightAnswersSame =
                              rightFromAssessment.length === rightFromData.length &&
                              rightFromAssessment.every((value, index) => value === rightFromData[index]);

                            if (areRightAnswersSame) {
                              setError(true);
                              return;
                            } else {
                              handleSubmit();
                              setValidateClicked(true);
                            }
                          }else if (currentAssessment.type === "Rearrange") {
                            const options = currentAssessment.options.filter(op => op.trim() !== "") || [];
                            const areAnswersSame =
                            options.length === answers.length &&
                            options.every((value, index) => value === answers[index]);
                            if (areAnswersSame) {
                              setError(true);
                              return;
                            } else {
                              handleSubmit();
                              setValidateClicked(true);
                            }
                            
                          }
                          else if(currentAssessment.type === "highlight"){
                            if(assessmentData[uniqueId]?.lastSelectedText===""){
                              setError(true);                            
                            }
                            else{
                              handleSubmit();
                             setValidateClicked(true);
                            }
                          }
                          else if (currentAssessment.type === "select the answers") {
                            // Check if all dropdowns are answered
                            const allAnswered = currentAssessment.options.every((option, index) => {
                              const dropdownId = option.id; 
                              return answers[dropdownId] && answers[dropdownId].trim() !== ""; 
                            });
                      
                            if (!allAnswered) {
                              setError(true); 
                              return;
                            }
                      
                            handleSubmit();
                            setValidateClicked(true);
                          }else if (currentAssessment.type === "Drag and Drop") {
                            const hasAtLeastOneAnswer = answers.some((col) =>
                              col.values.some((value) => value.trim() !== "")
                            );
                      
                            if (!hasAtLeastOneAnswer) {
                              setError(true); 
                              return;
                            } else {
                              handleSubmit(); 
                              setValidateClicked(true);
                            }
                          }   
                          else if (answers.length > 0) {
                            handleSubmit();
                            setValidateClicked(true);
                          } 
                          else {
                            setError(true);
                          }
                        }}
                      >
                        Submit
                      </button>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={style.bodyRight}>
              {result ? (
                <>
                  {summaryDetail&&Object.keys(summaryDetail).length>0&&
                <div className={style.summeryContainer}>
                  <div className={style.summeryTopContent}>
                    <div className={style.roundGraph}>
                      <Doughnut
                        data={{
                          labels: ["Completed", "Remaining"],
                          datasets: [
                            {
                              data: [
                                summaryDetail?.percentage || 0, 
                                100 - (summaryDetail?.percentage || 0), 
                              ],
            
                              backgroundColor: ["#03CD09", "#3E3D4212"],
                              borderWidth: 0,
                            },
                          ],
                        }}
                        options={{
                          cutout: "85%",
                          plugins: {
                            legend: {
                              display: false,
                            },
                          },
                        }}
                      />
                      <div
                        className={style.graphText}
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          pointerEvents: "none",
                        }}
                      >
                        {Math.round(summaryDetail?.percentage || 0)}%
                      </div>
                    </div>
                    <div className={style.summeryTopText}>
                      <p>Your score</p>
                    </div>
                  </div>
                  <div className={style.summeryBottomContent}>
                    <div className={style.summeryBottomData}>
                      <div className={style.summeryBottomDataLeft}>
                        <span style={{ background: "#00DFC7" }}>{summaryDetail?.correctAns || 0}</span>
                      </div>
                      <div className={style.summeryBottomDataRight}>
                        <p>Correct answers</p>
                      </div>
                    </div>
                    <div className={style.summeryBottomData}>
                      <div className={style.summeryBottomDataLeft}>
                        <span style={{ background: "#FF2443" }}>{summaryDetail?.incorrectAns || 0}</span>
                      </div>
                      <div className={style.summeryBottomDataRight}>
                        <p>Wrong answers</p>
                      </div>
                    </div>
                    <div className={style.summeryBottomData}>
                      <div className={style.summeryBottomDataLeft}>
                        <span style={{ background: "#FFBE00" }}>{summaryDetail?.skippedQuestions || 0}</span>
                      </div>
                      <div className={style.summeryBottomDataRight}>
                        <p>Skipped questions</p>
                      </div>
                    </div>
                    <div className={style.summeryBottomData}>
                      <div className={style.summeryBottomDataLeft}>
                        <span style={{ background: "#595BDB" }}>{summaryDetail?.totalQuestions || 0}</span>
                      </div>
                      <div className={style.summeryBottomDataRight}>
                        <p>No. of questions</p>
                      </div>
                    </div>
                  </div>
                </div>
                }
                  </>
              ) : (
                <>
                  <div className={style.timeContainer}>
                    <div className={style.timerIcon}>
                      <img src={TimerImage} alt="Timer" />
                    </div>
                    <div className={style.timerText}>
                    <p style={{ color: timeLeft <= 120 ? '#FF2443' : 'inherit' }} >{`${minutes}:${seconds < 10 ? `0${seconds}` : seconds} mins`}</p>
                    </div>
                  </div>
                  <div className={style.markContainer}>
                    <div className={style.markData}>
                      <div className={style.markDataLeft}>
                        <p>Answered</p>
                      </div>
                      <div
                        style={{ marginBottom: "8px" }}
                        className={style.markDataRight}
                      >
                        <p>
                          <span>{Object.values(assessmentData).filter(
                        (data) => data.status === "attended"
                      ).length}</span>/{totalCount}
                        </p>
                      </div>
                    </div>
                    <div className={style.markData}>
                      <div className={style.markDataLeft}>
                        <p>Not answered</p>
                      </div>
                      <div className={style.markDataRight}>
                      <p>{Object.values(assessmentData).filter(
                        (data) => data.status=== "skipped"
                      ).length}</p>
                      </div>
                    </div>
                  </div>
                  <div className={style.FinishButton}>
                    <button onClick={()=>{ 
                        handleEndTest()
                    }}>End Test</button>
                  </div>
                  <div className={style.questionPaletteHeader}>
                    <p>Question Palette:</p>
                  </div>
                  <div className={style.questionPalette}>
                    {numbers.map((number, index) => {
                      const questionId = assessment[index]?.unique_id;
                      const status =  assessmentData[questionId]?.status;

                      let backgroundColor;
                      if (currentIndex === index) {
                        backgroundColor = "#D9D9D9";
                      } else if (status === "skipped") {
                        backgroundColor = "#818085";
                      }else if (status === "attended") {
                        backgroundColor = "#BEBFFF";
                      } else {
                        backgroundColor = "#FFFFFF";
                      }

                      const numberStyle = {
                        backgroundColor,
                      };

                      return (
                        <div
                        onClick={() => {
                          const uniqueId = currentAssessment?.unique_id;
                          if (assessmentData[uniqueId]?.status === 'viewed') {
                            setAssessmentData((prev) => ({
                              ...prev,
                              [uniqueId]: {
                                ...prev[uniqueId],
                                status: 'skipped',
                              },
                            }));
                            if (uniqueId && assessmentData[uniqueId]) {
                              const currentAssmnt = assessment[currentIndex];
                              let resetAnswers;
                              let resetHighlightContent = "";
                              let resetLastSelectedText = "";
                              let resetAcrossClues = [];
                              let resetDownClues = [];
                              let highlightMultipleContent=""
                      
                              if (currentAssmnt.type === "Match the following" || currentAssmnt.type === "Match the following with image") {
                                const filteredLeft = (currentAssmnt.left || []).filter(
                                  (leftData) => leftData.trim() !== ""
                              );
                              const filteredRight = (currentAssmnt.right || []).filter(
                                  (rightData) => rightData.trim() !== ""
                              );
                                  resetAnswers = {
                                      left: filteredLeft,
                                      right: filteredRight,
                                  };
                              } else if (currentAssmnt.type === "Rearrange") {
                                  resetAnswers = currentAssmnt.options || [];
                              } else if (currentAssmnt.type === 'cross word puzzle') {
                                  resetAnswers = currentAssmnt.options.map(row => (
                                      row.map(cell => ({
                                          clueID: cell.clueID || "",
                                          clueNumber: cell.clueNumber,
                                          cluevalue: '',
                                          disabled: cell.cluevalue === '',
                                      }))
                                  ));
                      
                                  resetAcrossClues = currentAssmnt.left.map(clueObj => ({
                                      id: clueObj.id,
                                      number: clueObj.number,
                                      clue: clueObj.clue,
                                      answer: ''
                                  }));
                                  resetDownClues = currentAssmnt.right.map(clueObj => ({
                                      id: clueObj.id,
                                      number: clueObj.number,
                                      clue: clueObj.clue,
                                      answer: '' 
                                  }));
                              }else if (currentAssmnt.type === "highlight") {
                                resetHighlightContent =currentAssmnt.options.replace(
                                  /(<[^>]+)style=["'][^"']*color:\s*rgb\(255,\s*122,\s*0\);?[^"']*["']/g,
                                  '$1'
                              ) || ""; 
                              resetHighlightContent =resetHighlightContent.replace(/<span[^>]*>(.*?)<\/span>/g, '$1')|| ""; 
                    
                                resetLastSelectedText = "";
                            }else if (currentAssmnt.type === "highlight_multiple") {
                              highlightMultipleContent = currentAssmnt.options.replace(
                                  /(<[^>]+)style=["'][^"']*color:\s*rgb\(255,\s*122,\s*0\);?[^"']*["']/g,
                                  '$1'
                              );
                    
                              highlightMultipleContent = highlightMultipleContent.replace(/<span[^>]*>(.*?)<\/span>/g, '$1');
                              resetAnswers = [];
                            
                          }  else if(currentAssmnt.type === "Drag and Drop"){
                            const updatedAnswer = currentAssmnt.answer.map(item => {
                              return {
                                  ...item,
                                  values: item.values.map(() => "") 
                              };
                          });
                          resetAnswers = updatedAnswer;
                          }  else {
                                  resetAnswers = [];
                              }
                      
                              setAssessmentData((prev) => ({
                                  ...prev,
                                  [uniqueId]: {
                                      ...prev[uniqueId],
                                      answers: resetAnswers,
                                      highlightContent: resetHighlightContent,
                                      lastSelectedText: resetLastSelectedText,
                                      cluesAcross: resetAcrossClues,
                                      cluesDown: resetDownClues,
                                      previousRange: null,
                                      highlightMultipleContent:highlightMultipleContent,
                                      highlightMultipleRange:[]
                                  },
                              }));
                              setSelectedOptions((prev) => {
                                const { [uniqueId]: _, ...rest } = prev; 
                                return rest;
                            });
                          }
                          }
                          setCurrentIndex(number - 1);
                          setValidateClicked(false);
                        }}
                          key={number}
                          className={style.number}
                          style={numberStyle}
                        >
                          <p> {number}</p>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          <div className={style.info}><p>No Weekly Workout found</p></div>
        )}
        </>}
      </div>
      
      {finishPopup?
      <>
      <div className={style.popupOverlay}></div>
        <div className={style.Popup}>
          <div className={style.popupText}>
            <p>Are you sure you want to end the test?</p>
          </div>
          <div className={style.PopupButton}>
          <button
            className={style.noButton}
              onClick={() => {
                setFinishPopup(false);
              }}
            >
              No
            </button>
            <button
            className={style.yesButton}
              onClick={(e) => {
                e.preventDefault();
                clearInterval(timerRef.current); 
                assessmentSummary()
                setFinished(true)
                setTimeRunning(false)
              }}
            >
              Yes
            </button>
           
          </div>
        </div>
      </>:""}
      {achievementPopup &&(
         <>
         <div className={style.popupOverlay}></div>
         <div className={style.achievementPopup}>
<div className={style.achievementPopupSection}>
        <div className={style.achievementPopupHeder}>
          <h2>Hooray!</h2>
          <p>
            You just unlocked <span>{carouselData.length} Achievements</span>
          </p>
        </div>
        <div className={style.achievementCarousel}>
    <div
      className={style.carouselTrackContainer}
      style={{
        overflowX: carouselData.length > 1 ? "auto" : "hidden",
        display: "flex",
        justifyContent: carouselData.length === 1 ? "center" : "flex-start",
      }}
    >
      <div
        className={style.carouselTrack}
        style={{
          transform: "none",
        }}
      >
        {carouselData.map((item, index) => (
          <div key={index} className={style.carouselSlide}>
            <div className={style.achievementPopupImage}>
              <img src={item.active_img} alt={item.name} />
            </div>
            <div className={style.carouselText}>
              <h3>{item.name}</h3>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
</div>

      <div className={style.achievementPopupButton}>
        <button
          onClick={() => {
           setAchievementPopup(false);
          }}
        >
          OK
        </button>
      </div>
    </div>
         </>
      )}
      {collectPopup&&(
        <>
        <div className={style.popupOverlay}></div>
        <div className={style.achievementPopup}>
          <div className={style.collectPopupHeader}>
            <p>Congratulations</p>
          </div>
         <div className={style.collectPopupImageContainer}>
            <div className={style.collectPopupImage}>
           <img src={Coins} alt="Reward"/>
            </div>
            <div className={style.collectPopupLottie}>
            <Lottie
              animationData={coinsLottie}
              loop={true}
              duration
              autoplay
            />
            </div>
         </div>
          <div className={style.collectPopupText}>
            <span>You receive</span>
            <h3>{points} xp</h3>
            {/* <p>500 xp for completing a module within 6 weeks</p>
            <p>100 xp for scoring above 85%</p> */}
          </div>
        <div className={style.achievementPopupButton}>
        <button
          onClick={() => {
           setCollectPopup(false);
           const token = userDetails&&userDetails.auth_token; // Assuming `key` is the token
           dispatch(fetchUserDetails({ userid: userDetails&&userDetails._id?.$oid, token })); 
          }}
        >
          Collect All
        </button>
      </div>
        </div>
        </>
      )}
    </div>
  );
};

export default WeeklyWorkout;
