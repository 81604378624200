import React from "react";
import style from "./Maintenance.module.css";
import EnglishWagonLogo from "../../assests/English wagon logo_Actual 1.png";
import MaintenanceImage from "../../assests/web-maintenance 1.png"

const Maintenance = () => {
  return (
    <div className={style.container}>
      <div className={style.maintenanceSection}>
        <div className={style.maintenanceLogo}>
            <div className={style.maintenanceLogoImage}><img src={EnglishWagonLogo} alt="Logo"/></div>
        </div>
        <div className={style.maintenanceHeader}>
          <h4>We’re Improving Your Experience!</h4>
          <p>
            We’re currently performing some updates to make GrammarLocker™ even
            better for you.
          </p>{" "}
          <p>Please bear with us—we’ll be back shortly!</p>
        </div>
        <div className={style.maintenanceImageContainer}>
            <div className={style.maintenanceImage}>
                <img src={MaintenanceImage} alt="Maintenance"/>
            </div>
        </div>
        <div className={style.maintenanceBottomText}>
          <p>Thank you for your patience and understanding. </p>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
