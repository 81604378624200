import React from "react";
import style from "./HeadBar.module.css";
import EnglishWagonLogo from "../../assests/English wagon logo_Actual 1.png";
import { TbMenu2 } from "react-icons/tb";
import { useState,useRef,useEffect } from "react";



function HeadBar() {
  const settingsRef=useRef(null)
  const [showSidebar,setShowSidebar]=useState(false);
  const [closing, setClosing] = useState(false);

  const handleMenuToggle = () => {
    if (showSidebar) {
      setClosing(true);
      setTimeout(() => {
        setShowSidebar(false);
        setClosing(false);
      }, 500); 
    } else {
      setShowSidebar(true);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (settingsRef.current && !settingsRef.current.contains(event.target)) {
        handleMenuToggle();
      }
    };

    if (showSidebar) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showSidebar]);


  return (
    <div className={style.Container}>
      <div className={style.topBar}>
      <div className={style.topImg}>
          <a href="https://englishwagon.com" target="_blank" rel="noopener noreferrer">
            <img src={EnglishWagonLogo} alt="English Wagon Logo" />
          </a>
        </div>


        <div className={style.topBarMenu}>
          <a href="https://englishwagon.com/about" target="_blank" rel="noopener noreferrer">
            About
          </a>
          <a href="https://englishwagon.com/how-it-works" target="_blank" rel="noopener noreferrer">
            How it works
          </a>
          <a href="https://englishwagon.com/contact-us" target="_blank" rel="noopener noreferrer">
            Contact
          </a>
        </div>
        <div className={style.hamburgMenu}>
          <TbMenu2 onClick={(e)=>{
              e.stopPropagation();
           handleMenuToggle()
        }}
        />
        </div>
        {showSidebar&&(
          <div ref={settingsRef}  className={`${style.hamburgPopup} ${closing ? style.closing : ''}`}>
            <div className={style.hamburgMenuItem}>
            <a href="https://englishwagon.com/about" target="_blank" rel="noopener noreferrer">
            About
          </a>
            </div>
            <div className={style.hamburgMenuItem}>
            <a href="https://englishwagon.com/how-it-works" target="_blank" rel="noopener noreferrer">
            How it works
          </a>
            </div>
            <div className={style.hamburgMenuItem}>
            <a href="https://englishwagon.com/contact-us" target="_blank" rel="noopener noreferrer">
            Contact
          </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default HeadBar;
