import React, { useState,useRef, useEffect } from "react";
import LockImage from "../../assests/tiny-people-carrying-key.png";
import SuccessImage from "../../assests/PasswordSuccess.png";
import style from "./EmailVerification.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { baseurl } from "../Url";

const EmailVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.Email;
  const [contentShow, setContentShow] = useState("otpSection");
  const [otp, setOTP] = useState(Array(5).fill(""));
  const inputRefs = useRef([]);
  const [error,setError]=useState(false);
  const [otpSend,setOtpSend]=useState(false)

  const handleChange = (e, i) => {
    const value = e.target.value;

    if (/^[0-9]$/.test(value) || value === "") {
      setOTP((prev) => {
        const newOtp = [...prev];
        newOtp[i] = value;
        return newOtp;
      });

      // Move focus to the next input if a value is entered
      if (value && i < inputRefs.current.length - 1) {
        inputRefs.current[i + 1].focus();
      }
    }
  };

  const handleFocus = (i) => {
    // Optionally select the text when an input gains focus
    if (inputRefs.current[i]) {
      inputRefs.current[i].select();
    }
  };

  async function userValidation() {
    const body = {
      email: email,
      otp: otp.join(""),
    };

    await fetch(`${baseurl}/otp/validate/user`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
            setContentShow("successSection")
            setOTP(Array(5).fill(""));
            setTimeout(() => {
                navigate("/subscribe",{state:{
                  userID:data.data[0]?.user_id,
                  Username:data.data[0]?.Username
                }})
                
            }, 2000);
        } else  {
          if(data.description==="Invalid OTP or email"){
            setError(true);
          }
        }
      });
  }

  async function resendOtp() {
    const body = {
      email: email,
    };

    await fetch(`${baseurl}/otp/resend`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setOtpSend(true);
        } 
      });
  }
  useEffect(()=>{
    if(otpSend){
      setTimeout(() => {
        setOtpSend(false);
      }, 4000);
    }
  },[otpSend])
  return (
    <div className={style.container}>
      <div className={style.contentLeft}>
        <div className={style.resetCard}>
          {contentShow === "otpSection" ? (
            <div className={style.cardContent}>
              <div className={style.cardHeader}>
                <h4>Verification</h4>
                <span>Enter the verification code below.</span>
              </div>
              <div className={style.cardBody}>
              <div className={style.otpSection}>
              {otp.map((_, i) => (
        <div key={i} className={style.otpInput}>
          <input
            ref={(el) => (inputRefs.current[i] = el)}
            maxLength={1}
            required
            value={otp[i]}
            onChange={(e) => handleChange(e, i)}
            onFocus={() => handleFocus(i)}
          />
        </div>
      ))}
    </div>
    {error&&(<p className={style.errorText}>Invalid OTP</p>)}
    {otpSend&&(<p className={style.resendText}>OTP send</p>)}
                <div className={style.cardButton}>
                  <button
                    onClick={() => {
                      userValidation();
                    }}
                  >
                    Submit
                  </button>
                </div>
                <div className={style.cardBottomText}>
                  <p>
                    Didn’t receive code? <span onClick={resendOtp}>Resend</span>
                  </p>
                </div>
              </div>
            </div>
          ) : contentShow === "successSection" ? (
            <div className={style.successContent}>
              <div className={style.successImage}>
                <img src={SuccessImage} alt="Success" />
              </div>
              <div className={style.successText}>
                <p>Email verified successfully.</p>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className={style.contentRight}>
        <div className={style.rightImage}>
          <img src={LockImage} alt="Password reset" />
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
