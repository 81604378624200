import React, { useEffect, useState, useRef } from "react";
import style from "./Support.module.css";
import supportImage from "../../assests/subscriptionMail.png";
import customerSupportImage from "../../assests/flat-customer-support-illustration 1.png";
import { useSelector } from 'react-redux';
import { IoIosArrowDown,IoIosArrowUp } from "react-icons/io";
import { useLocation } from "react-router-dom";
import Loader from "../Loder/Loader";

const Support = () => {
  const location = useLocation();

  const { userDetails, status: userStatus, error: userError } = useSelector((state) => state.user);
  const [countryDetails,setCountryDetails]=useState("")
  const [isLoading, setIsLoading] = useState(true);
 
  const [faqs, setFaqs] = useState([
    {
      question:
        "How many exercises and tests are there? How are they organised?",
      answer:
        "In all, there are around 10,000+ - and these are regularly refreshed. In My GrammarLocker you have seven modules covering a wide range of topics and are tested on each topic as you go. Plus, you have Daily Drills of 20 questions to complete within a set time. Then your Weekly Workout tests everything you’ve completed so far.",
      isExpand: false,
    },
    {
      question: "How many modules are there and what is covered?",
      answer:
        "My GrammarLocker has seven modules, each with between three and nine topics. A wide range of areas are covered, from sentence structure to verbs, nouns, pronouns, adjectives, adverbs and prepositions. The syllabus corresponds to the Level B2 internationally-recognised standard, defined as equivalent to a “independent users of the language, who can understand the main ideas of complex texts and are comfortable in an English-speaking environment”.",
      isExpand: false,
    },
    {
      question:
        "What level of English do I need to benefit from GrammarLocker?",
      answer:
        "GrammarLocker is for people of any age who have studied to Grade 6 level or above to refresh and improve your English.",
      isExpand: false,
    },
    {
      question: "How do I know if I am making progress?",
      answer:
        "You have Daily Drills (20 questions) and Weekly Workouts covering each topic in every module, all to complete within a set time. So you are consistently testing your understanding of everything you’ve completed with your scores and progress tracked in your personal dashboard to show you the progress you’re making.",
      isExpand: false,
    },
    {
      question:
        "What if I can’t complete my Daily Drill every day? Or miss a Weekly Workout?",
      answer:
        "A new Daily Drill is released each day to be completed that same day. It is then replaced by the next Daily Drill, and so on. The Weekly Workout is released every Monday and available for the whole week until it is replaced by the next Weekly Workout the following Monday.<br/>This is to encourage you to complete a test every day and a workout once a week as this is the best way to accelerate your progress. ",
      isExpand: false,
    },
    {
      question:
        "If I don’t do well in one of my tests, can I go over the lessons again and re-take the test?",
      answer:
        "Of course. If you make a mistake, most questions give you a clear explanation of where you went wrong. That way, you can refresh your understanding by going back over the topic and go again! Your improved score will be included on your personal dashboard so you can track your progress.",
      isExpand: false,
    },
    {
      question:
        "What if I don’t understand where I have gone wrong in the tests?",
      answer:
        "If you make a mistake, in most cases (unless it is obvious) you’ll have a clear explanation of where you went wrong. That way, you can refresh your understanding by going back over the topic and go again!",
      isExpand: false,
    },
    {
      question:
        "What if I start the Daily Drill/Weekly Workout and am interrupted part way through?",
      answer:
        "The Daily Drill and Weekly Workout are timed just as an exam would be, so it’s important to set aside the required time to complete them. If they are not completed within the set time, then your test will be automatically submitted for marking. <br/>However, if the tests are interrupted because of a power failure or a technical issue, then you’ll be able to return to start over.",
      isExpand: false,
    },
    {
      question:
        "How long does my subscription last? Will I be able to finish the course in one subscription?",
      answer:
        "The estimated completion time for the entire course is 48 weeks. We want you to be able to finish the course so the subscription is valid for a year. However, we would urge you to keep up the momentum as regular learning, with pace, is how you will really see an improvement in your English.<br/>We also regularly review and add to the exercises available, so you will be able to find new questions throughout your subscription.",
      isExpand: false,
    },
    {
      question:
        "Is there another course I can do once I have completed GrammarLocker?",
      answer:
        "GrammarLocker is English Wagon’s first product and we will be adding more courses to our online English language school soon. They will be accessible from this website, so please check back regularly for our latest news and announcements.",
      isExpand: false,
    },
    {
      question: "How does the payment work?",
      answer: (
        <p>
          We will take a one-off payment of <span>{countryDetails==="India"?"₹799":"£9.99"}</span> for the year when you subscribe. All payment details are secure.
        </p>
      ),
      isExpand: false,
    },
    {
      question: "Will my subscription renew automatically?",
      answer:
        "No. The subscription is for a year and so no automatic subscription renewal will apply. You will be offered the opportunity to renew when you log in towards the end of your subscription period. If you miss the renewal deadline, you can simply go through the website and subscribe again.",
      isExpand: false,
    },
    {
      question:
        "What level of English will I achieve by completing GrammarLocker’s seven modules?",
      answer:
        "Our syllabus corresponds to the internationally-recognised standard of Level B2, which is defined as equivalent to a “independent users of the language, who can understand the main ideas of complex texts and are comfortable in an English-speaking environment”.",
      isExpand: false,
    },
    {
      question: "Does GrammarLocker contain every grammar point there is?",
      answer:
        "GrammarLocker provides a very thorough grounding in most areas, with 46 individual topics arranged across seven modules and over 10,000 exercises to put them into practice. However, we can’t guarantee that every question you may have is covered. <br/>At the same time, we want to ensure we’re responsive to the needs of our EnglishWagon community, so If you have any suggestions on what may be a helpful addition, please get in touch with us via the enquiry form and we will get back to you.",
      isExpand: false,
    },
    {
      question:
        "I only need to refresh certain grammar points: do I have to progress through all the modules?",
      answer:
        "No, you can select the individual module and topic to suit your needs and gaps. It’s always good to practise though, so we’d recommend you brush up on each topic! ",
      isExpand: false,
    },
    {
      question: "How do I earn points?",
      answer: (
        <>
          <p>
            You will see the best results by working in your GrammarLocker every
            day and keeping up the momentum as you progress through the modules,
            clocking up plenty of practice and revising regularly.
          </p>
          <p>
            So you are rewarded with points for consistency as well as
            performance – and the number of points you achieve set your status as
            Gold, Silver or Bronze, shown in your profile.
          </p>
          <p>
            You can earn points for scoring well in your topic exercises and
            tests; completing your modules in a timely way; regularly revising
            past topics and doing your daily drills every day and your weekly
            workouts every week. And, if you do all of those things, you get
            bonus points on top!
          </p>
          <div className={style.faqTable}>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Achievement</th>
                  <th>Xp</th>
                  <th>Achievement</th>
                  <th>Xp</th>
                  <th>Achievement</th>
                  <th>Xp</th>
                  <th>Achievement</th>
                  <th>Xp</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Average Score</td>
                  <td>85%+</td>
                  <td>100</td>
                  <td>60-84%</td>
                  <td>75</td>
                  <td>40-59%</td>
                  <td>50</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Pace</td>
                  <td>Complete Modules 1/2/5 within six weeks</td>
                  <td>500</td>
                  <td>Complete Module 3 within 10 weeks</td>
                  <td>500</td>
                  <td>Complete Module 4 within 12 weeks</td>
                  <td>500</td>
                  <td>Complete Module 6/7 within four weeks</td>
                  <td>500</td>
                </tr>
                <tr>
          <td>Regular revision</td>
          <td>One topic a week</td>
          <td>50</td>
          <td>Two topic a week</td>
          <td>100</td>
          <td>3+ topic a week</td>
          <td>150</td>
          <td></td>
          <td></td>
         </tr>
         <tr>
          <td>Daily Drill consistency</td>
          <td>15 days in a row</td>
          <td>105</td>
          <td>10 days in a row</td>
          <td>70</td>
          <td>5 days in a row</td>
          <td>35</td>
          <td></td>
          <td></td>
         </tr>
         <tr>
          <td>Average Daily Drill score</td>
          <td>80%+ every day over four weeks</td>
          <td>160</td>
          <td>70%+ every day over four weeks</td>
          <td>140</td>
          <td>60%+ every day over four weeks</td>
          <td>120</td>
          <td></td>
          <td></td>
         </tr>
         <tr>
          <td>Weekly Workout consistency</td>
          <td>Complete four Weekly Workouts in a row</td>
          <td>200</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
         </tr>
         <tr>
          <td>Average Weekly Workout score</td>
          <td>80%+ every week over four weeks</td>
          <td>160</td>
          <td>70%+ every week over four weeks</td>
          <td>140</td>
          <td>60%+ every week over four weeks</td>
          <td>120</td>
          <td></td>
          <td></td>
         </tr>
              </tbody>
            </table>
          </div>
        </>
      ),
      isExpand: false,
    },
    {
      question: "How do I reach Bronze, Silver or Gold performer?",
      answer: (
        <>
          <p>
            Your gold, silver and bronze status is decided by your total number of points and automatically updated when you achieve the required number of points.
          </p>
          <div className={style.statusTable}>
            <table>
              <tbody>
                <tr>
                  <td>Gold Performer</td>
                  <td>When you’ve accumulated 22,950+ points</td>
                </tr>
                <tr>
                  <td>Silver Performer</td>
                  <td>When you’ve accumulated 20,500+ points</td>
                </tr>
                <tr>
                  <td>Bronze Performer</td>
                  <td>When you’ve accumulated 18,100+ points</td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      ),
      isExpand: false,
    },    
    {
      question:
        "How do I earn badges?",
      answer:
        "You earn badges for your performance and your consistency as you go along – these are displayed in your profile as well as your dashboard. They are awarded based on your topic test scores and your average performance across the modules. Each one is displayed in the Badges section is in My Profile – click on them to see the detail of what they are awarded for.",
      isExpand: false,
    },
  ]);

  const apiKey = process.env.REACT_APP_COUNTRY_API_KEY;

  async function getCountry() {
    try {
      const res = await fetch(`https://pro.ip-api.com/json/?key=${apiKey}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      const country = data.country;
      setCountryDetails(country);
      
      const updatedFaqs = faqs.map((faq) => {
        if (faq.question === "How does the payment work?") {
          return {
            ...faq,
            answer: (
              <p>
                We will take a one-off payment of{" "}
                <span>{country === "India" ? "₹799" : "£9.99"}</span> for the
                year when you subscribe. All payment details are secure.
              </p>
            ),
          };
        }
        return faq;
      });

      setFaqs(updatedFaqs);
    } catch (error) {
      console.error("Error fetching country details:", error);
    } finally {
      console.log("final");
      
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
      
    }
  }
  
       useEffect(()=>{
         getCountry();
       },[location.state])
   

  const toggleExpand = (index) => {
    setFaqs((prevFAQ) =>
      prevFAQ.map((faq, i) =>
        i === index ? { ...faq, isExpand: !faq.isExpand } : faq
      )
    );
  };

  
  return (
    <div className={style.container}>
      {isLoading ? (
        <Loader /> 
            ) : (
      <div className={style.support}>
        <div className={style.welcomeSection}>
          <div className={style.welcomeSectionLeft}>
            <p>
              Hello{" "}
              {userStatus === "succeeded" &&
                userDetails &&
                userDetails.FirstName}
              !
            </p>
            <span>We are here to help you!</span>
          </div>
          <div className={style.welcomeSectionRight}>
            <div className={style.welcomeImageContainer}>
              <div className={style.welcomeImage}>
                <img src={supportImage} alt="welcome" />
              </div>
            </div>
          </div>
        </div>
        <div className={style.supportSection}>
          <div className={style.supportSectionLeft}>
            <div className={style.supportHeder}>
              <p>FAQs and Help</p>
            </div>
            {/* <div className={style.supportSubHeder}>
              <p>Need assistance? </p>
              <span>Our support team is here to help. <br/>
              Contact us directly for quick and personalized support. </span>
            </div> */}
            <div className={style.supportDetail}>
              {/* <p>+44 079 5858 0357 </p> */}
              <p>
                Our team is here everyday to make sure that everything runs
                smoothly for you
              </p>

              <p>
                Most queries are answered by our FAQs, below, so that’s the
                first place to check if you have a question.
              </p>

              <p>
                However, if you have another query or are experiencing any
                issues, please send us a message at{" "}
                <a href="mailto:enquiries@englishwagon.com">enquiries@englishwagon.com</a> and we will get back to
                you as soon as possible.
              </p>
            </div>
          </div>
          <div className={style.supportSectionRight}>
            <div className={style.supportSectionImage}>
              <img src={customerSupportImage} alt="" />
            </div>
          </div>
        </div>
        <div className={style.faqSection}>
          <div className={style.faqHeader}>
            <p>FAQs</p>
          </div>
          <div className={style.faqBody}>
            {faqs.length > 0
              ? faqs.map((faq, i) => (
                  <div key={i} className={style.faqCard}>
                    <div className={style.faqQuestion}>
                      <div className={style.faqQuestionLeft}>
                        <p>{faq.question}</p>
                      </div>
                      <div
                        onClick={() => toggleExpand(i)}
                        className={style.faqQuestionRight}
                      >
                        {faq.isExpand ? <IoIosArrowUp /> : <IoIosArrowDown />}
                      </div>
                    </div>
                    {faq.isExpand && (
                      <div className={style.faqAnswer}>
                      {typeof faq.answer === "string" ? (
                <p
                  dangerouslySetInnerHTML={{ __html: faq.answer }}
                />
              ) : (
                faq.answer
              )}

                      </div>
                    )}
                  </div>
                ))
              : ""}
                                 
                 
          </div>
        </div>
      </div>
    )}
    </div>
  );
};

export default Support;
