import React from "react";
import { useNavigate,useLocation } from "react-router-dom";
import style from "./Subscription.module.css";
import tick from "../../assests/tick.png";
import { baseurl } from "../Url";
import { useState,useEffect } from "react";

function Subscription() {
  const navigate = useNavigate();
  const location =useLocation();
  const UserID =location.state?.userID
  const UserName=location.state?.Username
  const trialCount=location.state?.count
  const is_Renewal=location.state?.renewal
  const coins=location.state?.coins
  const lastPath=location.state?.previousPath
  
  const [agreed,setAgreed]=useState(false)
  const [renewClicked,setRenewClicked]=useState(false)
  const [countryDetails,setCountryDetails]=useState("")

  const apiKey = process.env.REACT_APP_COUNTRY_API_KEY;
  const productID = process.env.REACT_APP_PRODUCT_ID;

  const handleCheckboxChange = (event) => {
    setAgreed(event.target.checked);
  };
  

  async function createStripeOrder() {
    const requestData={
       user_id:UserID,
       product:`${productID}`,
       currency:countryDetails==="India"?"INR":"POUND",
       country:countryDetails,
       success_url: `${window.location.origin}/subscribe/success/?session_id={CHECKOUT_SESSION_ID}`,
       cancel_url:`${window.location.origin}/subscribe/failed/?session_id={CHECKOUT_SESSION_ID}`,
       amount:renewClicked?699:799,
       product_name:"GrammarLocker",
       is_discount:renewClicked?true:false

     }
    await fetch(`${baseurl}/create/subscription/checkout-session`, {
      method: "POST",
      body: JSON.stringify(
        requestData
      ),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
          window.location.assign(`${data.data[0].url}`);
        }
      });
  }

  async function trialUser() {
    const requestData={
       user_id:UserID,
     }
    await fetch(`${baseurl}/user/trail`, {
      method: "POST",
      body: JSON.stringify(
        requestData
      ),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === true) {
         navigate("/start")
         localStorage.setItem("trialUser",true)
         localStorage.setItem(
          "access-token",
          JSON.stringify(data.data[0]?.access_token)
        );
        localStorage.setItem("ClubID", data.data[0]?.club_id);
        localStorage.setItem("ORGID", data.data[0]?.org_id);
        localStorage.setItem("USERID", data.data[0]?.user_id);
        localStorage.setItem("mentor", data.data[0]?.is_mentor);
        localStorage.setItem("username", data.data[0]?.username);
        localStorage.setItem("firstName",data.data[0]?.first_name);
        
        }
      });
  }

  async function getCountry() {
    await fetch(`https://pro.ip-api.com/json/?key=${apiKey}`,{
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => res.json())
    .then((data)=>{
      setCountryDetails(data.country)
      
    })
  }
  useEffect(()=>{
    getCountry();
  },[location.state])

  useEffect(() => {
    if (UserID === null || UserID === undefined) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (lastPath === 'Summary') {
      window.history.pushState(null, document.title, window.location.href);
      const handlePopState = () => {
        window.history.pushState(null, document.title, window.location.href);
      };
      window.addEventListener('popstate', handlePopState);
      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }
  }, [lastPath]);

  return (
    <div className={style.Container}>
      <div className={style.mainBody}>
        <div className={style.user}>
          <p>
            {" "}
            Almost there, <span>{UserName}!</span>{" "}
          </p>
        </div>
{/* 
        <div className={style.course}>
          <p> Courses Available : </p>
        </div> */}

        <div className={style.cardContainer}>
          <div className={style.courseCard}>
            <div className={style.CardHeader}>
              <div className={style.package}>
                <p>
                  01 <span>GrammarLocker</span>
                </p>
              </div>
              <div className={style.slogan}>
                <p>
                  The ultimate online, interactive English grammar workbook.
                </p>
              </div>
            </div>

            <div className={style.CardBody}>
             {/* <div className={style.pointsHeader}> <p> What’s Included? </p></div> */}

              <div className={style.points}>
                <img src={tick} alt="tick" />
                <p>
                  <span>Unlimited access</span> to English grammar lessons
                </p>
              </div>
              <div className={style.points}>
                <img src={tick} alt="tick" />
                <p>
                10,000+ <span>online, interactive exercises</span> {" "}
                </p>
              </div>
             
              <div className={style.points}>
                <img src={tick} alt="tick" />
                <p>
                  <span>Daily drills </span>and <span>weekly workouts</span> to
                 test your knowledge and understanding
                </p>
              </div>
              <div className={style.points}>
                <img src={tick} alt="tick" />
                <p>
                <span>Personal online dashboard</span> to monitor your progress
                </p>
              </div>
            </div>
          </div>

          <div className={style.subscribeCard}>
            <div className={style.subscribeCardBox}>
              <div className={style.subscribeCardHead}>
                <div className={style.summary}>
                  <p className={style.summaryTitle}>Order:</p>
                  <p className={style.summarySpan}>
                  Full, 24/7 access to GrammarLocker for one year
                  </p>
                </div>

                <div className={style.amount}>
                  <p className={style.amountTitle}>Sub Total:</p>
                  {countryDetails==="India"? <p className={style.amountSpan}>
                    {" "}
                    INR <span>799</span>
                  </p>:
                  <p className={style.amountSpan}>
                    {" "}
                    £ <span>9.99 </span>
                  </p>}
                </div>
              </div>
              {/* {is_Renewal&&coins>="2500"?
              <div className={style.discountSection}>
                <button disabled={renewClicked} onClick={()=>setRenewClicked(true)} style={{background:renewClicked?"#03CD09":"",color:renewClicked?"#ffffff":""}}>You have <span style={{color:renewClicked?"#ffffff":""}}>2500 xp. Use Now</span></button>
                <div className={style.discountAmount}>
               <div className={style.discountAmountContainer}>
                  <div className={style.discountAmountLeft}><p>Discount</p></div>
                  <div className={style.discountAmountRight}><p>₹ {renewClicked?"100":"0"}</p></div>
               </div>
               <div className={style.discountAmountContainer}>
                <div className={style.discountAmountLeft}><h4>Total</h4></div>
                <div className={style.discountAmountRight}><p>₹ {renewClicked?"599":"699"}</p></div>
                </div>
                </div>
               
              </div>
              :""} */}

              <div className={style.declare}>
                <label>
                  <input onChange={handleCheckboxChange} type="checkbox" name="remember" /> &nbsp;
                  <p>I accept all the <a href="https://englishwagon.com/terms-conditions/" target="_blank" rel="noopener noreferrer">terms and conditions</a></p>
                </label>
              </div>

              <div className={style.SubscriptionBtn}>
                <button style={{backgroundColor:!agreed?"#898890":"",opacity:!agreed?"0.75":""}} disabled={!agreed} onClick={() => createStripeOrder()} >Subscribe Now </button>
              </div>

              <div className={style.separator}>
                <span>OR</span>
            </div>

            <div className={style.startTrail}>
              <p> Unlock your potential with a free trial of GrammarLocker </p>
              
                <button  style={{backgroundColor:!agreed||trialCount>=1||trialCount>="1"?"#898890":"",opacity:!agreed||trialCount>=1||trialCount>="1"?"0.75":""}} 
                disabled={!agreed||trialCount>=1||trialCount>="1"}
                 onClick={() => trialUser()}>Start Trial </button>
              </div>

            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subscription;
